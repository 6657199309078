import { ISortableConfig } from 'agito.ng-sortable';
import cloneDeep from 'lodash-es/cloneDeep';
import groupBy from 'lodash-es/groupBy';
import isEqual from 'lodash-es/isEqual';
import sortBy from 'lodash-es/sortBy';
import uniqBy from 'lodash-es/uniqBy';
import { Subscription } from 'rxjs';

import { IAlertParameters } from '../../src/app/entities/alert';
import { IApplicationError } from '../../src/app/entities/application-error';
import { UnitGroup } from '../../src/app/generated-modules/Hilti.PE.Purchaser.Common.Units';
import {
    PurchaserDataEntity
} from '../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Data';
import {
    AnchorEntity
} from '../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Data.Anchor';
import {
    DesignEntity
} from '../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Data.Design';
import {
    AnchorType, WorkingCondition
} from '../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Data.Enums';
import {
    MenuType
} from '../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Display.Enums';
import {
    PropertyUpdateResultEntity
} from '../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.UpdateData';
import { GuidService } from '../../src/app/guid.service';
import { format } from '../../src/app/helpers/string-helper';
import { urlPath } from '../../src/app/ModuleConstants';
import { PropertyMetaData } from '../../src/app/properties/properties';
import { CodeListService, ProjectCodeList } from '../../src/app/services/code-list.service';
import { DateTimeService } from '../../src/app/services/date-time.service';
import { ErrorHandlerService, ModalDialogType } from '../../src/app/services/error-handler.service';
import { LocalizationService } from '../../src/app/services/localization.service';
import { LoggerService, LogType } from '../../src/app/services/logger.service';
import { environment } from '../../src/environments/environment';
import { AddNewAnchor } from '../Controls/AddNewAnchor/AddNewAnchor';
import { ApplicationSettings } from '../Controls/ApplicationSettings/ApplicationSettings';
import { Archive } from '../Controls/Archive/Archive';
import {
    ConnectionsGrid, ConnectionsGridController, IConnection, IConnectionAnchor, IConnectionSize
} from '../Controls/ConnectionsGrid/ConnectionsGrid';
import { ContactHilti } from '../Controls/ContactHilti/ContactHilti';
import { Bind, Translation } from '../Controls/controls';
import { CreateNewBom } from '../Controls/CreateNewBom/CreateNewBom';
import { Dropdown } from '../Controls/Dropdown/Dropdown';
import { DropdownItem } from '../Controls/DropdownItem/DropdownItem';
import { ModalTranslation } from '../Controls/Modal/BaseModal';
import { ShortcutIconPopup } from '../Controls/ShortcutIconPopup/ShortcutIconPopup';
import { UserAgreementSettings } from '../Controls/UserAgreementSettings/UserAgreementSettings';
import { UserSettings } from '../Controls/UserSettings/UserSettings';
import { IBaseDesign } from '../Entities/Design';
import { getProjectDeps, Project, ProjectEvent } from '../Entities/Project';
import { AuthenticationService } from '../Services/authentication-service';
import { BrowserService } from '../Services/browser-service';
import { ChangesService } from '../../src/app/services/changes.service';
import { DataService } from '../Services/data-service';
import {
    CantArchiveProjectsBecauseDocumentInUse, DocumentService, ProjectType
} from '../Services/document-service';
import { IRequestConfig } from '../Services/http-interceptor-service';
import { ImportService } from '../Services/import-service';
import { LicenseService } from '../Services/license-service';
import { LoadingService } from '../Services/loading-service';
import { ModalService } from '../Services/modal-service';
import { NumberService } from '../Services/number-service';
import { ProductInformationService } from '../Services/product-information.service';
import { PromiseService } from '../Services/promise-service';
import { RegionOrderService } from '../Services/region-order-service';
import { TrackingService } from '../Services/tracking-service';
import { UnitService } from '../Services/unit-service';
import { ProjectAndDesignView, UserService } from '../Services/user-service';
import { UserSettingsService } from '../Services/user-settings-service';
import { ArticleNumberNotFound } from './bom-controller';
import { IRootControllerScope } from './root-controller';

export interface ILeftNavigationItem {
    id: string;
    image: string;
    tooltip: string;
    click: () => void;
    bottomSeparator?: boolean;
}

export enum DesignSort {
    Newest = 1,
    Oldest = 2,
    AtoZ = 3,
    ZtoA = 4
}

enum DesignGroup {
    none,
    list,
}

enum EditProjectType {
    none,
    new,
    newSub,
    rename,
    newCompanyProject
}

export interface IDisplayProject {
    id: string;
    name: string;
    projectType: ProjectType;
    designs: IDisplayDesign[];
    subProjects: IDisplayProject[];
    created: Date;
    owner: boolean;
    parentId: string;
    isCompanyProject: boolean;
    readOnly: boolean;
}

interface IDisplayDesign {
    id: string;
    name: string;
    created: Date;
    createdDisplay: string;
    project: IDisplayProject;
}

interface IAnchorSummary {
    id: string;
    designId: string;
    articleNumber?: string;
    name: string;
    sizeName?: string;
    articleQuantity?: number;
    total: string;
    anchorType: AnchorType;
    manual: boolean;
    tFixMax?: number;
}

enum PendingAction {
    newProject,
    newSubProject,
    renameProject,
    import,
    downloadProject,
    downloadAllDesigns
}

/**
 *
 */
export class ProjectAndDesignController {
    public static readonly $inject = [
        '$scope',
        'logger',
        '$location',
        'user',
        'authentication',
        'guid',
        '$timeout',
        'loading',
        'modal',
        'codeList',
        '$q',
        '$http',
        'document',
        'localization',
        'userSettings',
        'browser',
        'import',
        'unit',
        'regionOrder',
        'number',
        '$window',
        'data',
        'tracking',
        'errorHandler',
        'license',
        'productInformation',
        'changes',
        'promise',
        'dateTime'
    ];

    public static readonly projectContentUpdatedStateId = 'projectContentUpdatedStateId';

    public selectedProject: IDisplayProject;
    public projects: IDisplayProject[];
    public designs: IDisplayDesign[];
    public editProjectName: string;
    public editProjectType: EditProjectType;
    public designGroup: DesignGroup;
    public connectionsGrid: ConnectionsGrid;
    public purchaserDataEntity: PurchaserDataEntity;
    public pendingAction: PendingAction;
    public createNewBomModal: CreateNewBom;
    public addNewAnchorModal: AddNewAnchor;
    public shortcutIconPopup: ShortcutIconPopup;
    public applicationSettings: ApplicationSettings;
    public userSettingsModal: UserSettings;
    public userAgreementSettingsModal: UserAgreementSettings;
    public leftNavigation: ILeftNavigationItem[];
    public leftNavigationSelectedButton: ILeftNavigationItem;
    public designView: ProjectAndDesignView;
    public workingConditionDropdown: Dropdown<WorkingCondition>;
    public archive: Archive;
    public anchorSummary: IAnchorSummary[];
    public sortableLeftMenuProject: ISortableConfig<void>;
    public contactHilti: ContactHilti;
    public projectFilterString: string;
    public projectFilterResult: { [id: string]: boolean };

    public uploadDesignView: (...args: any[]) => any;
    public user: UserService;

    private newProjectParent: IDisplayProject;
    private selectedRenameProject: IDisplayProject;

    private $scope: IRootControllerScope;
    private logger: LoggerService;
    private $location: ng.ILocationService;
    private authentication: AuthenticationService;
    private guid: GuidService;
    private $timeout: ng.ITimeoutService;
    private $q: ng.IQService;
    private $http: ng.IHttpService;
    private loading: LoadingService;
    private modal: ModalService;
    private codeList: CodeListService;
    private localization: LocalizationService;
    private document: DocumentService;
    private userSettings: UserSettingsService;
    private browser: BrowserService;
    private importService: ImportService;
    private unit: UnitService;
    private regionOrder: RegionOrderService;
    private numberService: NumberService;
    private $window: ng.IWindowService;
    private dataService: DataService;
    private tracking: TrackingService;
    private onServiceErrorHandler: ErrorHandlerService;
    private license: LicenseService;
    private productInformation: ProductInformationService;
    private changes: ChangesService;
    private promise: PromiseService;
    private dateTime: DateTimeService;

    private defaultProject: Project;
    private destroyed: boolean;
    private localizationChangeSubscription: Subscription;

    /**
     * Initializes a new instance of the ProjectAndDesignController class.
     */
    constructor(
        $scope: IRootControllerScope,
        logger: LoggerService,
        $location: ng.ILocationService,
        user: UserService,
        authentication: AuthenticationService,
        guid: GuidService,
        $timeout: ng.ITimeoutService,
        loading: LoadingService,
        modal: ModalService,
        codeList: CodeListService,
        $q: ng.IQService,
        $http: ng.IHttpService,
        document: DocumentService,
        localization: LocalizationService,
        userSettings: UserSettingsService,
        browser: BrowserService,
        importService: ImportService,
        unit: UnitService,
        regionOrder: RegionOrderService,
        numberService: NumberService,
        $window: ng.IWindowService,
        dataService: DataService,
        tracking: TrackingService,
        onServiceErrorHandler: ErrorHandlerService,
        license: LicenseService,
        productInformation: ProductInformationService,
        changes: ChangesService,
        promise: PromiseService,
        dateTime: DateTimeService
    ) {
        this.$scope = $scope;
        this.logger = logger;
        this.$location = $location;
        this.user = user;
        this.authentication = authentication;
        this.guid = guid;
        this.$timeout = $timeout;
        this.$q = $q;
        this.$http = $http;
        this.loading = loading;
        this.modal = modal;
        this.codeList = codeList;
        this.localization = localization;
        this.document = document;
        this.userSettings = userSettings;
        this.browser = browser;
        this.importService = importService;
        this.unit = unit;
        this.regionOrder = regionOrder;
        this.numberService = numberService;
        this.$window = $window;
        this.dataService = dataService;
        this.tracking = tracking;
        this.onServiceErrorHandler = onServiceErrorHandler;
        this.license = license;
        this.productInformation = productInformation;
        this.changes = changes;
        this.dateTime = dateTime;
        this.promise = promise;

        this.logger.log('ProjectAndDesignController::ctor', LogType.debug);

        this.init();
    }

    public static findPurchaserDesignAnchorSizes(anchor: AnchorEntity, anchorType: AnchorType, guid: GuidService, depth = 0) {
        let sizes: IConnectionSize[] = [];

        if (anchor != null) {
            const articleNumber = anchor.ArticleNumber == ArticleNumberNotFound ? null : anchor.ArticleNumber;

            sizes.push({
                name: anchor.ArticleName,
                articleQuantity: anchor.ArticleQuantity,
                tFixMax: anchor.TfixMax,
                articleNumber
            });

            if (depth > 0 && anchor.SimilarAnchors != null && anchor.SimilarAnchors.length > 0) {
                throw new Error('Nested SimilarAnchors are not allowed.');
            }

            for (const similarAnchor of anchor.SimilarAnchors || []) {
                sizes.push(...ProjectAndDesignController.findPurchaserDesignAnchorSizes(similarAnchor, anchorType, guid, ++depth));
            }
        }

        // remove duplicates
        sizes = uniqBy(sizes, size => size.articleNumber != null && size.articleNumber != '' ? size.articleNumber : guid.new());

        // sort by article quantity then by article number
        sizes = sortBy(sizes, size => size.articleQuantity, size => size.articleNumber);

        return sizes;
    }

    public get showSubjectDataRights() {
        const regionLanguage = this.userSettings.getRegionLanguage();
        return regionLanguage?.dataSubjectRightsUrl != null && regionLanguage.dataSubjectRightsUrl != '';
    }


    public get projectFilterPlaceholder() {
        return this.localization.getLocalizedString('Agito.Hilti.Purchaser.ProjectAndDesign.Navigation.Projects.Search');
    }

    public get isAllDesignsLoading() {
        const loading = Object.values(this.document.projects).some(p => p.loading)
            || this.pendingAction === PendingAction.downloadAllDesigns;
        return loading;
    }

    public get hasProject() {
        return this.projects != null ? this.projects.length > 0 : false;
    }

    public get hasDesignConnections() {
        if (this.user.project.purchaserDataEntity?.Designs == null) {
            this.logger.log('ProjectAndDesignController.hasDesignConnections: purchaserDataEntity is null', LogType.warn);
            return false;
        }

        return (this.user.project.purchaserDataEntity != null ? this.user.project.purchaserDataEntity.Designs.length > 0
            && this.user.project.purchaserDataEntity.Designs.some((design) => design.IsIncluded) : false) ||
            (this.designView == ProjectAndDesignView.allDesigns && Object.values(this.document.projects).some(p => p.purchaserDataEntity?.Designs.some(d => d.IsIncluded)));
    }

    public get hasAnyDesignConnections() {
        return Object.values(this.document.projects).some(p => p.designs != null && Object.keys(p.designs).length > 0);
    }

    public get draftsProject() {
        return this.document.draftsProject;
    }

    public get disableBomButton() {
        return (this.selectedProject == null && this.designView != ProjectAndDesignView.allDesigns && this.designView != ProjectAndDesignView.drafts) ||
            this.pendingAction != null ||
            !this.hasDesignConnections ||
            this.anchorSummary.length < 1;
    }

    public get hasSummary() {
        return this.anchorSummary != null && this.anchorSummary.length > 0;
    }

    public get isArchiveVisible() {
        return (this.document.projectsArchive || []).length > 0;
    }

    public get archiveButtonText() {
        return this.localization.getLocalizedString('Agito.Hilti.Purchaser.ProjectAndDesing.Navigation.Projects.ArchiveButton') + ` (${(this.document.projectsArchive || []).length})`;
    }

    public get userName() {
        if (this.user.isExternalRussianUser) {
            return this.userSettings.ccmsUserSettings != null
                ? this.userSettings.ccmsUserSettings.FullName
                : '';
        }

        return this.userSettings.settings.user.general.name.value?.trim()
            ? this.userSettings.settings.user.general.name.value
            : this.user.authentication.userName;
    }

    public get showManageHiltiAccount() {
        const link = this.getManageHiltiAccountUrl();
        return link != null && link != '';
    }

    private get projectDeps() {
        return getProjectDeps(
            this.changes,
            this.localization,
            this.$http,
            this.logger,
            this.document,
            this.$q,
            this.$scope,
            this.guid,
            this.promise,
            this.dateTime,
            this.onServiceErrorHandler,
            this.modal,
            this.browser,
            this.userSettings,
            this.dataService,
            this.tracking,
            this.unit
        );
    }

    public getOverViewTranslation(translationKey: string, countId: number) {
        const countValue = this.document.overviewItemCount[countId] || 0;
        return format(this.localization.getLocalizedString(translationKey) + ' ({0})', countValue);
    }

    public openUserSettings() {
        this.userSettingsModal.open();
    }

    public upgradeLicense() {
        const getProfis3Url = this.userSettings.getProfis3Url();

        if (getProfis3Url != null) {
            window.open(getProfis3Url, '_blank');
        }
    }

    public openManageHiltiAccount() {
        const link = this.getManageHiltiAccountUrl();
        window.open(link, '_blank');
    }

    public openUserAgreementSettings() {
        this.userAgreementSettingsModal.open();
    }

    public openSubjectDataRights() {
        const regionLanguage = this.userSettings.getRegionLanguage();  // get link according to regional settings
        window.open(regionLanguage.dataSubjectRightsUrl, '_blank');
    }

    public openShortcutIconPopup() {
        this.shortcutIconPopup.open();
    }

    public filterProjects() {
        for (const project of this.projects) {
            this.projectFilterResult[project.id] = this.doesProjectMatchSearchFilter(project);

            for (const subproject of project.subProjects) {
                this.projectFilterResult[subproject.id] = this.doesSubprojectMatchSearchFilter(project, subproject);
            }
        }
    }

    public selectDesignView(designView: ProjectAndDesignView) {
        this.designView = designView;
        this.user.autoSelectDesignId = null;
    }

    public selectProject(project: IDisplayProject) {
        // do not select project with read only is true
        if (!project.readOnly) {
            this.selectedProject = project;
            this.user.autoSelectDesignId = null;
        }
    }

    public newProject() {
        this.showEditProject(EditProjectType.new);
    }

    public newCompanyProject() {
        this.showEditProject(EditProjectType.newCompanyProject);
    }

    public editProjectInputBlur() {
        setTimeout(() => {
            const activeElement = document.activeElement;

            if (activeElement.closest('.new-project') == null) {
                this.$scope.$apply(() => this.editProjectConfirm());
            }
        });
    }

    public editProjectConfirm() {
        if (this.editProjectType == EditProjectType.none || this.pendingAction != null) {
            return;
        }

        switch (this.editProjectType) {
            case EditProjectType.newCompanyProject:
            case EditProjectType.new:
                this.editProjectNew();

                break;
            case EditProjectType.newSub:
                this.editProjectNewSub();

                break;
            case EditProjectType.rename:
                this.editProjectRename();

                break;
            default:
                throw new Error('Unknown EditProjectType.');
        }
    }

    public editProjectReject() {
        this.closeNewProject();
    }

    public newSubProject(project: IDisplayProject) {
        this.newProjectParent = project;

        this.showEditProject(EditProjectType.newSub);
    }

    public renameProject(project: IDisplayProject) {
        this.selectedRenameProject = project;

        this.showEditProject(EditProjectType.rename, project.name);
    }

    public archiveProject(project: IDisplayProject) {
        const deletedProjectId = project.id;

        const pCandidate = this.document.findProjectById(deletedProjectId);

        pCandidate.loading = true;

        this.document.archiveProject(deletedProjectId)
            .then(x => {
                if (pCandidate.subProjects && pCandidate.subProjects != null && Object.keys(pCandidate.subProjects).length > 0) {
                    for (const p in pCandidate.subProjects) {
                        this.projects = this.projects.filter((it) => it.id != pCandidate.subProjects[p].id);
                    }
                }

                this.projects = this.projects.filter((it) => it.id != deletedProjectId);
                this.projects.forEach(p => {
                    p.subProjects = p.subProjects.filter(p2 => p2.id != deletedProjectId);
                });

                if (deletedProjectId == this.selectedProject.id) {
                    this.designView = ProjectAndDesignView.allDesigns;
                }
                else { // optimization: selectedProject watch already performs refresh
                    this.refreshProjectsAndDesigns();
                }
            })
            .catch((e) => {
                pCandidate.loading = false;

                if (e instanceof CantArchiveProjectsBecauseDocumentInUse) {
                    const cantEx: CantArchiveProjectsBecauseDocumentInUse = e as CantArchiveProjectsBecauseDocumentInUse;
                    const descriptionForDocument = this.localization.getLocalizedString('Agito.Hilti.Purchaser.ProjectAndDesing.Alerts.CannotRemoveProjectWithLockedDocuments.DescriptionForSpecificDocument');
                    let description = this.localization.getLocalizedString('Agito.Hilti.Purchaser.ProjectAndDesing.Alerts.CannotRemoveProjectWithLockedDocuments.Description');
                    description += '\n';
                    let docCount = 0;
                    for (const doc of cantEx.documentsInQuestion) {
                        docCount++;
                        if (docCount > 5) {
                            description += '\n\t\t' + this.localization.getLocalizedString('Agito.Hilti.Purchaser.ProjectAndDesing.Alerts.CannotRemoveProjectWithLockedDocuments.DescriptionForTooMuchDocuments');
                            break;
                        }
                        description += '\n\t\t' + descriptionForDocument.replace('{document}', doc.document).replace('{user}', doc.username);
                    }
                    description += '\n';
                    const param: IAlertParameters = {
                        title: this.localization.getLocalizedString('Agito.Hilti.Purchaser.ProjectAndDesing.Alerts.CannotRemoveProjectWithLockedDocuments.Title'),
                        message: description,
                        applicationError: {
                            responsePayload: e
                        } as IApplicationError
                    };
                    this.modal.alert.open(param);
                }
            });
    }

    public selectImportFile() {
        const input = document.querySelector<HTMLInputElement>('.import-design-input');
        if (input.value == null || input.value == '') {
            this.$timeout(() => { input.click(); }, 0, false);
        }
    }

    public importFileSelected(projectDesign: File | Blob, name?: string) {
        const input = document.querySelector<HTMLInputElement>('.import-design-input');

        if (input.value != null && input.value != '') {
            const file = input.files[0];

            // clear input
            input.value = null;

            this.import(file);
        }
    }

    public openApplicationSettings() {
        this.applicationSettings.open();
    }

    public openSupportLink() {
        this.modal.support.open();
    }

    public openHiltiDataPrivacyUrl() {
        const hiltiDataPrivacyUrl = this.userSettings.getHiltiDataPrivacyUrl();
        if (hiltiDataPrivacyUrl != null) {
            window.open(hiltiDataPrivacyUrl, '_blank');
        }
    }

    public navigate(path: string) {
        this.$location.path(path);
    }

    public logout() {
        this.$location.path(urlPath.logout).search('type', 'invalidate');
    }

    public isProjectDraft(project: IDisplayProject) {
        return project.projectType == ProjectType.draft;
    }

    public createNewBom() {
        this.createNewBomModal.project = this.user.project;
        if (this.designView != ProjectAndDesignView.allDesigns) {
            this.createNewBomModal.connectionDetails = (this.user.project.model[PropertyMetaData.Design_DesignConnections.id] as DesignEntity[]);
        }
        else {
            // Only loading visible projects and it's designs. Some projects will be only partially loaded with some of its designs not loaded as they are not visible. Filtering those designs out or they will cause undefined exception.
            this.createNewBomModal.connectionDetails = Object.values(this.document.projects)
                .reduce((list, project) => [...list, ...((project.model[PropertyMetaData.Design_DesignConnections.id] as DesignEntity[]) ?? [])], [] as DesignEntity[])
                .filter(design => design != null);

            this.createNewBomModal.project.purchaserDataEntity.Designs = this.createNewBomModal.connectionDetails;
        }

        this.createNewBomModal.open();
    }

    public addNewAnchor() {
        this.addNewAnchorModal.project = this.user.project;
        this.addNewAnchorModal.open();
    }

    public openDeleteProjectDialog() {
        this.modal.confirmChange.open('delete-project-popup',
            this.translate('Agito.Hilti.Purchaser.DeleteProject.Title'),
            this.translate('Agito.Hilti.Purchaser.DeleteProject.Content'),
            this.translate('Agito.Hilti.Purchaser.DeleteProject.Delete'),
            this.translate('Agito.Hilti.Purchaser.DeleteProject.Cancel'),
            () => {
                this.document.removeProject(this.user.project.id)
                    .then(() => {
                        this.selectDesignView(ProjectAndDesignView.allDesigns);
                        this.refreshProjectsAndDesigns();
                    });
                this.modal.confirmChange.close(true);
            },
            () => {
                this.modal.confirmChange.close(false);
            });
    }

    public scrollToItem(item: IDisplayProject) {
        if (this.projects.length == 0) {
            return;
        }

        const selectedIndex = this.projects.findIndex(project => this.selectedProject.id == project.id);
        const previousIndex = selectedIndex - 1 > 0 ? selectedIndex - 1 : 0;

        const gridBodyElement = document.querySelector('.project-and-design-view .content .content-control .content-control-body .navigation .projects-container .container-content .projects');
        if (gridBodyElement != null) {
            gridBodyElement.scrollTop = 36 * previousIndex;
        }
    }

    public translate(key: string) {
        return this.localization.getLocalizedString(key);
    }

    public downloadProject(project: IDisplayProject) {
        const url = `${environment.baseplateApplicationWebServiceUrl}GetProjectZip/${project.id}`;

        const config: IRequestConfig = {
            responseType: 'blob',
            headers: {
                Accept: 'application/zip'
            },
            ignoreErrors: true
        };

        this.pendingAction = PendingAction.downloadProject;
        const rawProject = Object.values(this.document.projectsFlat).find(p => p.id === project.id);
        rawProject.loading = true;
        this.$http.get<Blob>(url, config)
            .then(response => {
                const zip = response.data;

                this.browser.downloadBlob(zip, project.name + '.zip');
            })
            .catch(response => {
                if (response.status == 404) {
                    this.onServiceErrorHandler.showProjectArchivedModal();
                }
                else {
                    const param: IApplicationError = {
                        response,
                        endPointUrl: url
                    };
                    this.modal.alert.openServiceError(param);
                }
            })
            .finally(() => {
                this.pendingAction = null;
                rawProject.loading = false;
            });
    }

    public downloadAllDesigns() {
        const language = this.localization.selectedLanguage;
        const url = `${environment.baseplateApplicationWebServiceUrl}GetAllProjectsZip`;
        const config: ng.IRequestShortcutConfig = {
            responseType: 'blob',
            headers: {
                Accept: 'application/zip'
            },
            params: { language },
        };

        this.pendingAction = PendingAction.downloadAllDesigns;
        this.$http.get<Blob>(url, config)
            .then(response => {
                const zip = response.data;

                this.browser.downloadBlob(zip, this.translate('Agito.Hilti.Purchaser.ProjectAndDesign.Navigation.Designs.AllDesigns') + '.zip');
            })
            .finally(() => {
                this.pendingAction = null;
            });
    }

    public emptyProject(project: IDisplayProject) {
        const folder = Object.values(this.document.projectsFlat).find(p => p.id == project.id);
        return folder == null || !(Object.keys(folder.designs ?? []).length > 0 || Object.values(folder.subProjects).some(p => Object.keys(p.designs ?? []).length > 0));
    }

    public completelyEmptyProject(project: IDisplayProject) {
        if (this.user.project.purchaserDataEntity?.Designs == null) {
            this.logger.log('ProjectAndDesignController.hasDesignConnections: purchaserDataEntity is null', LogType.warn);
            return false;
        }

        return this.user.project.purchaserDataEntity.Designs.length == 0;
    }

    public archivePopupOpen() {
        this.archive.open();
    }

    public isProjectDropdownOpend(projectId: string) {
        return document.getElementById('project-and-design-navigation-project-' + projectId + '-dropdown-button').getAttribute('aria-expanded') == 'true';
    }

    public isDesignDropdownOpend(designId: string) {
        return document.getElementById('project-and-design-main-design-' + designId + '-template-options-dropdown-button').getAttribute('aria-expanded') == 'true';
    }

    public projectMenuToggled(opend: boolean) {
        const projectsScroll = document.querySelector('.projects-scroll') as HTMLElement;

        if (opend) {
            // Disable scroll
            const currentPosition = projectsScroll.scrollTop;
            projectsScroll.onwheel = (e) => { e.preventDefault(); };
            projectsScroll.onscroll = () => { projectsScroll.scrollTop = currentPosition; };
        }
        else {
            // Enable scroll
            projectsScroll.onscroll = () => { };
            projectsScroll.onwheel = () => { };
        }
    }

    public dropdownFromBodyDirection(projectId: string) {
        const scrollPosition = document.querySelector('.projects-scroll')?.parentElement.offsetTop;
        const projectListItem = document.getElementById('project-and-design-navigation-project-' + projectId);
        const dropdownItem = document.getElementById('dropdown-direction-' + projectId);

        if (scrollPosition == null || dropdownItem == null || projectListItem == null) {
            return;
        }

        // we have to put top on 0 otherwise it wont calculate document.height correctly
        dropdownItem.style.top = '0';
        let topPosition = scrollPosition + projectListItem.offsetTop + projectListItem.clientHeight;

        if (document.body.clientHeight < scrollPosition + projectListItem.offsetTop + dropdownItem.offsetHeight + projectListItem.clientHeight) {
            topPosition = scrollPosition + projectListItem.offsetTop - dropdownItem.offsetHeight;
        }

        const dropdownButtonRect = document.getElementById(`project-and-design-navigation-project-${projectId}-dropdown-button`).getBoundingClientRect();
        dropdownItem.style.top = `${topPosition}px`;
        dropdownItem.style.left = `${dropdownButtonRect.left}px`;
        dropdownItem.style.position = 'fixed';
    }

    public summaryAnchorDescription(articleNumber: string, sizeName: string, articleQuantity: number, tFixMax: number, anchorType: AnchorType, manual: boolean) {
        if (articleNumber == null || articleNumber == '') {
            return this.localization.getLocalizedString('Agito.Hilti.Purchaser.PleaseContactHilti');
        }

        let description = '';

        if (sizeName != null && sizeName != '') {
            description += (description != '' ? ' ' : '') + sizeName;
        }

        if (articleQuantity != null && anchorType != AnchorType.SieveSleeve) {
            if (anchorType == null || anchorType == AnchorType.Insert) {
                description += (description != '' ? ' ' : '') + this.unit.formatInternalValueAsDefault(articleQuantity, UnitGroup.Length);
            }
            else if (anchorType == AnchorType.Mortar || anchorType == AnchorType.Capsule) {
                description += (description != '' ? ' ' : '') + this.unit.formatInternalValueAsDefault(articleQuantity, UnitGroup.Volume);
            }
            else {
                throw new Error('Unknown AnchorType.');
            }
        }

        if ((anchorType == null || anchorType == AnchorType.Insert) && tFixMax != null && !manual) {
            description += (description != '' ? ' ' : '') + `(t fix max: ${this.unit.formatInternalValueAsDefault(tFixMax, UnitGroup.Length)})`;
        }

        return description;
    }

    public hasArticleNumber(articleNumber: string) {
        if (articleNumber == null || articleNumber == '') {
            return false;
        }

        return true;
    }

    /**
     * Initialize main controller
     */
    private init() {
        this.logger.log('ProjectAndDesignController::init', LogType.debug);

        this.projectFilterResult = {};

        this.$scope['DesignGroup'] = DesignGroup;
        this.$scope['EditProjectType'] = EditProjectType;
        this.$scope['PendingAction'] = PendingAction;
        this.$scope['DesignView'] = ProjectAndDesignView;

        this.projectStateChanged = this.projectStateChanged.bind(this);

        // watches
        this.$scope.$watch('ctrl.projectFilterString', this.filterProjects.bind(this));
        this.$scope.$watch('ctrl.selectedProject', this.onSelectedProjectChange.bind(this));
        this.$scope.$watch('ctrl.designView', this.onDesignViewChange.bind(this));
        this.$scope.$watch('ctrl.connectionsGrid.connections', this.onConnectionsChanged.bind(this), true);

        this.sortableLeftMenuProject = {
            handle: '.drag-handle-static',
            store: {
                get: (sortable) => {
                    return this.regionOrder.get(MenuType.LeftMenuProject);
                },
                set: (sortable) => {
                    this.regionOrder.update(sortable.toArray(), MenuType.LeftMenuProject);
                }
            }
        };

        // clear user design
        this.user.design = null;

        this.designGroup = DesignGroup.none;
        this.editProjectType = EditProjectType.none;

        // select project
        this.designs = [];

        this.editProjectType = EditProjectType.none;

        this.handleAutoSelectDesign();

        this.projects = this.toDisplayProjects(this.getDocumentProjects());

        this.createNewBomModal = new CreateNewBom();
        this.addNewAnchorModal = new AddNewAnchor();
        this.shortcutIconPopup = new ShortcutIconPopup();

        this.contactHilti = new ContactHilti();

        this.applicationSettings = new ApplicationSettings({
            unitLength: new Bind(`ctrl.user.project.model[${PropertyMetaData.Option_UnitLength.id}]`),
            unitVolume: new Bind(`ctrl.user.project.model[${PropertyMetaData.Option_UnitVolume.id}]`),
            unitArea: new Bind(`ctrl.user.project.model[${PropertyMetaData.Option_UnitArea.id}]`),
            languageLCID: new Bind(`ctrl.user.project.model[${PropertyMetaData.Option_LanguageLCID.id}]`),
            regionId: new Bind(`ctrl.user.project.model[${PropertyMetaData.Option_Region.id}]`),
            loading: new Bind(`ctrl.user.project.loading`)
        });

        this.connectionsGrid = new ConnectionsGrid({
            loadData: () => {
                const project = this.user.project;

                return this.loadPurchaserDataEntity()
                    .then(connections => {
                        if (this.designView == ProjectAndDesignView.allDesigns) {
                            this.connectionsGrid.fullyLoaded = this.connectionsGrid.connections.length + connections.length >= Object.keys(this.document.designsFlat).length;
                        }
                        else {
                            this.connectionsGrid.fullyLoaded = project.fullyLoaded;
                        }

                        return connections;
                    });
            },
            events: {
                [ConnectionsGrid.connectionsChanged]: this.userConnectionsChanged.bind(this)
            }
        });

        this.workingConditionDropdown = new Dropdown<WorkingCondition>({
            disabled: new Bind(`(ctrl.selectedProject != null && ctrl.selectedProject.projectType == 1) || ctrl.selectedProject == null || ctrl.pendingAction != null || ctrl.user.project.model[${PropertyMetaData.Project_WorkingCondition.id}] == null`),
            required: true,
            id: 'application-settings-working-condition',
            title: new Translation('Agito.Hilti.Purchaser.ProjectAndDesign.ProjectWorkingConditions'),
            items: [],
            selectedValue: new Bind(`ctrl.user.project.model[${PropertyMetaData.Project_WorkingCondition.id}]`),
            notSelectedText: ''
        });

        this.archive = new Archive({
            onRestore: () => this.refreshProjectsAndDesigns.bind(this)()
        });

        this.userSettingsModal = new UserSettings();
        this.userAgreementSettingsModal = new UserAgreementSettings();

        this.refreshProjects();
        this.initLeftNavigation();
        this.refreshProjects();

        // Project search filter
        this.filterProjects();

        this.sizeConnectionsGrid = this.sizeConnectionsGrid.bind(this); // firefox fix, must have specified height
        requestAnimationFrame(this.sizeConnectionsGrid);

        this.setWorkingConditionDropdownItems();
        this.localizationChangeSubscription = this.localization.localizationChange.subscribe(() => {
            this.setWorkingConditionDropdownItems();
        });

        this.$scope.$on('$destroy', this.destroy.bind(this));

        if (this.license.displayTrialInformation) {
            this.license.displayTrialInformation = false;
            this.modal.trialBanner.open();
        }
    }

    private doesProjectMatchSearchFilter(project: IDisplayProject) {
        if (this.projectFilterString) {
            return this.projectSearchMatchPrimitive(project.name) || project.subProjects.some(subProject => this.projectSearchMatchPrimitive(subProject.name));
        }

        return true;
    }

    private doesSubprojectMatchSearchFilter(project: IDisplayProject, subproject: IDisplayProject) {
        if (this.projectFilterString) {
            return this.projectSearchMatchPrimitive(project.name) || this.projectSearchMatchPrimitive(subproject.name);
        }

        return true;
    }

    private projectSearchMatchPrimitive(name: string) {
        return name.trim().toLowerCase().indexOf(this.projectFilterString.trim().toLowerCase()) > -1;
    }

    private import(projectDesign: File | Blob, name?: string) {
        this.pendingAction = PendingAction.import;

        // if showing all designs import new designs into draft folder
        const project = (this.designView == ProjectAndDesignView.allDesigns || this.designView == ProjectAndDesignView.drafts) ? Object.values(this.document.projects).find(p => p.projectType == ProjectType.draft) : this.selectedProject;

        const internalProject = Object.values(this.document.projectsFlat).find(p => p.id == project.id);
        const promise = (this.designView == ProjectAndDesignView.allDesigns) ? internalProject.loadPurchaserDataEntityForProject(this.connectionsGrid.lastAction, true) : this.$q.when(null as DesignEntity[]);

        return promise
            .then(() => this.importService.importDesign(internalProject, projectDesign, name))
            .then(() => {
                this.document.recountDesignsInProjects();
            })
            .finally(() => {
                this.pendingAction = null;
            });
    }

    private handleAutoSelectDesign(): void {
        let useDefaultProject = true;

        if (this.user.autoSelectDesignId != null) {
            const autoSelectDesign = Object.values(this.document.designsFlat).find(d => d.id == this.user.autoSelectDesignId);

            if (!autoSelectDesign) {
                this.modal.confirmChange.open(
                    'design-does-not-exist-modal',
                    new ModalTranslation('Agito.Hilti.Purchaser.DocumentService.Alerts.DesignDoesNotExist.Title'),
                    new ModalTranslation('Agito.Hilti.Purchaser.DocumentService.Alerts.DesignDoesNotExist.Message'),
                    new ModalTranslation('Agito.Hilti.Purchaser.Ok'),
                    null,
                    () => this.modal.confirmChange.close());
            }
            else {
                useDefaultProject = false;
                const projectId = autoSelectDesign.projectId;
                this.user.project = Object.values(this.document.projectsFlat).find(p => p.id == projectId);
                this.selectedProject = this.toDisplayProject(this.user.project);

                if (this.selectedProject.projectType == ProjectType.draft) {
                    this.designView = ProjectAndDesignView.drafts;
                    this.selectedProject = null;
                }
            }
        }

        if (useDefaultProject) {
            // Currently we do not want to select the Drafts folder as soon as the app loads.
            this.defaultProject = new Project(this.projectDeps, { id: null, projectType: ProjectType.default });
            this.user.project = this.defaultProject;
            // BUDQBP-24287: Setting it to null since it can cause errors if generated id is the same as in db.
            this.selectedProject = null;
        }
    }

    private initLeftNavigation() {
        this.leftNavigation = [
            {
                id: 'home',
                image: 'sprite-loading-logo-small',
                bottomSeparator: true,
                tooltip: 'Agito.Hilti.Purchaser.Navigation.Home',
                click: () => {
                    this.navigate(urlPath.projectAndDesign);
                }
            },
            {
                id: 'bom',
                image: 'sprite-bom',
                tooltip: 'Agito.Hilti.Purchaser.Navigation.BOM',
                click: () => {
                    this.navigate(urlPath.bom);
                }
            },
        ];

        this.leftNavigationSelectedButton = this.leftNavigation[0];
    }

    private editProjectNew() {
        if (this.editProjectName == null || this.editProjectName.trim() == '') {
            this.editProjectReject();
        }
        else if (this.document.projectNameExists(this.editProjectName.trim(), null)) {
            this.onServiceErrorHandler.showExistingProjectNameModal(ModalDialogType.project);
        }
        else {
            const project = new Project(this.projectDeps, {
                id: this.guid.new(),
                name: this.editProjectName.trim(),
                owner: this.editProjectType == EditProjectType.new,
                isCompanyProject: this.editProjectType == EditProjectType.newCompanyProject,
            });

            // call document service
            this.pendingAction = PendingAction.newProject;
            this.document.saveProject(project, ModalDialogType.project)
                .then(x => {
                    this.user.autoSelectDesignId = null;
                    this.refreshProjects();
                    this.selectedProject = this.toDisplayProject(project);
                    this.scrollToItem(this.selectedProject);

                    this.projectFilterResult[project.id] = this.doesProjectMatchSearchFilter(this.selectedProject);
                })
                .finally(() => {
                    this.closeNewProject();
                    this.pendingAction = null;

                    this.refreshDesigns();
                });
        }
    }

    private editProjectNewSub() {
        if (this.editProjectName == null || this.editProjectName.trim() == '') {
            this.editProjectReject();
        }
        else if (this.document.projectNameExists(this.editProjectName.trim(), this.newProjectParent.id)) {
            this.onServiceErrorHandler.showExistingProjectNameModal(ModalDialogType.subproject);
        }
        else {
            const project = new Project(this.projectDeps, {
                id: this.guid.new(),
                name: this.editProjectName.trim(),
                owner: this.editProjectType == EditProjectType.newSub,
                isCompanyProject: this.editProjectType == EditProjectType.newCompanyProject,
            });

            // set parent project
            const parentProject = this.document.findProjectById(this.newProjectParent.id);
            project.parentId = parentProject.id;

            // call document service
            this.pendingAction = PendingAction.newSubProject;
            this.document.saveProject(project, ModalDialogType.subproject)
                .then(x => {
                    this.user.autoSelectDesignId = null;
                    this.refreshProjects();
                    this.selectedProject = this.toDisplayProject(project);
                    this.scrollToItem(this.selectedProject);

                    this.projectFilterResult[project.id] = this.doesSubprojectMatchSearchFilter(this.toDisplayProject(parentProject), this.selectedProject);
                })
                .finally(() => {
                    this.closeNewProject();
                    this.pendingAction = null;
                });
        }
    }

    private editProjectRename() {
        if (this.editProjectName == null || this.editProjectName.trim() == '' || this.selectedRenameProject.name == this.editProjectName) {
            this.editProjectReject();
        }
        else if (this.selectedRenameProject != null && this.document.projectNameExists(this.editProjectName.trim(), this.selectedRenameProject.parentId)) {
            this.onServiceErrorHandler.showExistingProjectNameModal(this.selectedRenameProject.parentId ? ModalDialogType.subproject : ModalDialogType.project);
        }
        else {
            const project = this.document.findProjectById(this.selectedRenameProject.id);
            const prevProjName = project.name;

            project.name = this.editProjectName;
            this.selectedRenameProject.name = this.editProjectName;
            const projectLevelType = this.selectedRenameProject.parentId ? ModalDialogType.subproject : ModalDialogType.project;

            // call document service
            this.pendingAction = PendingAction.renameProject;
            this.document.saveProject(project, projectLevelType)
                .then(x => {
                    this.refreshProjects();
                    this.scrollToItem(this.selectedRenameProject);
                })
                .catch(() => {
                    project.name = prevProjName;
                    this.refreshProjects();
                })
                .finally(() => {
                    this.closeNewProject();
                    this.pendingAction = null;
                });
        }
    }

    private showEditProject(editProjectType: EditProjectType, editProjectName?: string) {
        this.editProjectType = editProjectType;
        this.editProjectName = editProjectName;

        setTimeout(() => {
            document.querySelector<HTMLInputElement>('.project-and-design-view .edit-project-input')?.focus();
        });
    }

    private closeNewProject() {
        this.editProjectType = EditProjectType.none;
        this.selectedRenameProject = null;
        this.editProjectName = null;
        this.newProjectParent = null;

        setTimeout(() => {
            // set focus on something else
            document.querySelector<HTMLInputElement>('.project-and-design-view #project-and-design-navigation-project-' + this.selectedProject.id)?.focus();
        });
    }

    private sortProjects(projects: IDisplayProject[]) {
        if (projects == null) {
            return null;
        }

        const displayProjects = sortBy(projects, (project) => (project.name || '').toLowerCase());

        for (const project of displayProjects) {
            project.subProjects = this.sortProjects(project.subProjects);
        }

        return displayProjects;
    }

    private onSelectedProjectChange() {
        // remove events
        if (this.user.project != null && this.designView != ProjectAndDesignView.allDesigns) {
            this.user.project.off(ProjectEvent.stateChanged, this.projectStateChanged);
        }

        if (this.selectedProject != null) {

            const selectedID = this.selectedProject.id;

            for (const pKey in this.document.projects) {
                this.document.projects[pKey].off(ProjectEvent.stateChanged, this.projectStateChanged);
            }

            this.designView = null;
            const foundProject = this.document.findProjectById(selectedID);
            this.user.project = foundProject ?? this.defaultProject;
            this.refreshDesigns();

            const project = this.user.project;

            this.connectionsGrid.lastAction = null;
            this.loadPurchaserDataEntity(true)
                .then(connections => {
                    if (this.selectedProject.id == selectedID) {
                        this.connectionsGrid.connections = connections;
                        this.connectionsGrid.fullyLoaded = project.fullyLoaded;
                    }
                });

            // add events
            project.on(ProjectEvent.stateChanged, this.projectStateChanged);
        }
    }

    private loadPurchaserDataEntity(loadFromStart?: boolean) {
        const project = this.user.project;

        if (this.designView == ProjectAndDesignView.allDesigns) {
            return this.loadConnectionsForProjects(0, [], ProjectAndDesignController.projectContentUpdatedStateId);
        }
        else {
            return project.loadPurchaserDataEntityForProject(this.connectionsGrid.lastAction, loadFromStart, ProjectAndDesignController.projectContentUpdatedStateId, this.user.autoSelectDesignId)
                .then(designs => this.convertDesignsWithDateToConnections(designs))
                .then(connections => this.addDesignsForProject(project, connections));
        }
    }

    private loadConnectionsForProjects(index: number, connections: IConnection[], stateId?: string): ng.IPromise<IConnection[]> {
        const pKey = Object.keys(this.document.projects)[index];
        return this.document.projects[pKey].loadPurchaserDataEntityForProject(this.connectionsGrid.lastAction, this.document.projects[pKey].loadFromStart, stateId)
            .then(designs => {
                if (designs.length > 0) {
                    const pKey = Object.keys(this.document.projects)[index];
                    connections.push(...this.convertDesignsWithDateToConnections(designs));
                    this.document.projects[pKey].loadFromStart = false;
                }
                return designs;
            })
            .then(designs => {
                if (designs.length > 0) {
                    connections = this.addDesignsForProject(this.document.projects[pKey], connections);
                }
            })
            .then(() => {
                const pKey = Object.keys(this.document.projects)[index];

                // load next project
                if (this.document.projects[pKey].fullyLoaded && connections.length < Project.loadSize && index < Object.keys(this.document.projects).length - 1) {
                    return this.loadConnectionsForProjects(++index, connections, stateId);
                }
                // keep loading same project designs
                else if (!this.document.projects[pKey].fullyLoaded && connections.length < Project.loadSize) {
                    return this.loadConnectionsForProjects(index, connections, stateId);
                }
                // return when we have enough new connections
                else {
                    return this.$q.when(connections);
                }
            });
    }

    private projectStateChanged(project: Project, state: PropertyUpdateResultEntity, stateId?: string) {
        // only update if the project is still selected
        if (this.user.project != null && this.user.project.id == project.id || this.designView == ProjectAndDesignView.allDesigns) {
            // don't update if state changed because of us (we already update the grid with .then)
            if (stateId != ProjectAndDesignController.projectContentUpdatedStateId) {
                if (this.designView != ProjectAndDesignView.allDesigns) {
                    this.connectionsGrid.connections = this.addDesignsForProject(this.user.project, this.convertDesignsWithDateToConnections(state.PurchaserData.Designs));
                }
                else {
                    this.connectionsGrid.connections = Object.values(this.document.projects).filter(p => !p.loadFromStart).reduce((arr, p) =>
                        [...arr, ...this.addDesignsForProject(p, this.convertDesignsWithDateToConnections(p.purchaserDataEntity.Designs))],
                        [] as IConnection[]);
                }

                this.connectionsGrid.fullyLoaded = (this.designView != ProjectAndDesignView.allDesigns && this.user.project.fullyLoaded) ||
                    (this.designView == ProjectAndDesignView.allDesigns && Object.values(this.document.projects).every(p => p.fullyLoaded));
            }
        }
    }

    private addDesignsForProject(project: Project, connections: IConnection[]) {
        if (project.fullyLoaded) {
            if (project?.purchaserDataEntity?.Designs != null) {
                const datelessDesigns = project.purchaserDataEntity.Designs.filter(design => design.CreatedDate == null);
                if (datelessDesigns.length > 0) {
                    connections = [...connections, ...this.convertDesignsToConnectionsInternal(datelessDesigns)];
                }
            }
        }

        return connections;
    }

    private convertDesignsWithDateToConnections(designs: DesignEntity[]) {
        const otherDesigns = designs.filter(design => design.CreatedDate != null);

        const connections = this.convertDesignsToConnectionsInternal(otherDesigns);
        // sort by date created
        return sortBy(connections, connection => {
            if (this.user.project?.purchaserDataEntity?.Designs != null) {
                const purchaserDesign = this.user.project.purchaserDataEntity.Designs.find((it) => it.DesignId == connection.id);
                if (purchaserDesign?.CreatedDate != null) {
                    const createdDate = new Date(purchaserDesign.CreatedDate as any);
                    return -(createdDate instanceof Date ? createdDate.getTime() : purchaserDesign.CreatedDate as any as number);
                }
            }

            return Number.MAX_SAFE_INTEGER;
        });
    }

    private convertDesignsToConnectionsInternal(designs: DesignEntity[]) {
        const connections: IConnection[] = [];
        const groupedDesigns = groupBy(designs, design => design.DesignId);

        for (const designId in groupedDesigns) {
            const designs = groupedDesigns[designId];
            const design = designs[0];

            // find mechanical and chemical design
            let mechanicalDesign: DesignEntity = null;
            let chemicalDesign: DesignEntity = null;
            let sieveSleeveDesigns: DesignEntity[] = [];

            if (designs.length > 1) {
                mechanicalDesign = designs.find(design => design.AnchorType == null || design.AnchorType == AnchorType.Insert);
                chemicalDesign = designs.find(design => design.AnchorType == AnchorType.Capsule || design.AnchorType == AnchorType.Mortar);
                sieveSleeveDesigns = designs.filter(design => design.AnchorType == AnchorType.SieveSleeve);

                if (mechanicalDesign == null || chemicalDesign == null) {
                    throw new Error('Invalid anchor type.');
                }
            }
            else {
                mechanicalDesign = designs[0];
            }

            // find sizes
            const mechanicalSizes = ProjectAndDesignController.findPurchaserDesignAnchorSizes(mechanicalDesign.SelectedAnchor, null, this.guid);
            const chemicalSizes = chemicalDesign != null ? ProjectAndDesignController.findPurchaserDesignAnchorSizes(chemicalDesign.SelectedAnchor, AnchorType.Mortar, this.guid) : null;

            // map
            connections.push({
                id: design.DesignId,
                designName: design.DesignName,
                designInfo: {
                    anchorDiameter: design.SelectedAnchor.AnchorDiameter,
                    anchorFamilyName: design.SelectedAnchor.AnchorFamilyName,
                    embedmentDepth: design.SelectedAnchor.EmbedmentDepth,
                    holeSize: design.SelectedAnchor.CuttingDiameter,
                    plateThickness: design.SelectedAnchor.BaseplateThickness
                },
                mechanicalAnchor: {
                    id: mechanicalDesign.SelectedAnchor.AnchorId,
                    name: mechanicalDesign.SelectedAnchor.AnchorName,
                    sizes: mechanicalSizes,
                    selectedSize: mechanicalDesign.SelectedAnchor.ArticleNumber == ArticleNumberNotFound ? null : mechanicalDesign.SelectedAnchor.ArticleNumber,
                    total: mechanicalDesign.ComputedAmount
                },
                chemicalAnchor: chemicalDesign != null ? {
                    id: chemicalDesign.SelectedAnchor.AnchorId,
                    name: chemicalDesign.SelectedAnchor.AnchorName,
                    sizes: chemicalSizes,
                    selectedSize: chemicalDesign.SelectedAnchor.ArticleNumber == ArticleNumberNotFound ? null : chemicalDesign.SelectedAnchor.ArticleNumber,
                    total: chemicalDesign.ComputedAmount,
                    invalidVolume: !chemicalDesign.SelectedAnchor.IsManuallyAdded && chemicalDesign.VolumeAmount == null
                } : null,
                numberOfAnchors: design.AnchorsInDesign,
                required: design.AnchorMultiplier,
                selected: design.IsIncluded,
                manual: design.SelectedAnchor.IsManuallyAdded,
                designStatus: design.DesignStatus,
                sieveSleeveAnchors: sieveSleeveDesigns.map(design => ({
                    id: design.SelectedAnchor.AnchorId,
                    name: design.SelectedAnchor.AnchorName,
                    sizes: ProjectAndDesignController.findPurchaserDesignAnchorSizes(design.SelectedAnchor, AnchorType.SieveSleeve, this.guid),
                    selectedSize: design.SelectedAnchor.ArticleNumber == ArticleNumberNotFound ? null : design.SelectedAnchor.ArticleNumber,
                    total: design.ComputedAmount
                }) as IConnectionAnchor)
            });
        }

        return connections;
    }

    private getAllDesignsFromProjects(projects: IDisplayProject[]) {
        // var ret = _.uniq(_.flatten(_.map(projects, (project) => [...project.designs, ..._.flatten(_.map(project.subProjects, (subProject) => subProject.designs))])), false, );
        // return ret;
        // sorry js link is not clear enough for me
        const ret = projects.map(x => x.designs).flat(); // I think that this will get us only designs from the first level of projects, is this ok?
        return ret;
    }

    private toDisplayDesign(design: IBaseDesign, displayProject: IDisplayProject): IDisplayDesign {
        if (design == null) {
            return null;
        }

        return {
            id: design.id,
            name: design.designName,
            created: design.createDate,
            createdDisplay: this.localization.moment(design.createDate).format('LL'),
            project: displayProject
        };
    }

    private toDisplayProject(project: Project) {
        if (project == null) {
            return null;
        }

        const displayProject: IDisplayProject = {
            id: project.id,
            name: project.projectType != ProjectType.draft ? project.name : this.localization.getLocalizedString('Agito.Hilti.Purchaser.Documents.SpecialProject.draft'),
            projectType: project.projectType,
            subProjects: Object.values(project.subProjects).map((subProject) => this.toDisplayProject(subProject)),
            designs: null,
            created: project.createDate,
            owner: project.owner,
            parentId: project.parentId,
            isCompanyProject: project.isCompanyProject,
            readOnly: project.readOnly
        };

        displayProject.designs = Object.values(project.designs).map((design) => this.toDisplayDesign(design, displayProject));

        return displayProject;
    }

    private toDisplayProjects(projects: Project[]) {
        const displayProjects = projects.map((project) => this.toDisplayProject(project));

        return this.sortProjects(displayProjects);
    }

    private refreshDesigns() {
        this.designs = this.getAllDesignsFromProjects(this.projects);
        this.document.recountDesignsInProjects();
    }

    private refreshProjects() {
        this.projects = this.toDisplayProjects(this.getDocumentProjects());

        // refresh selected project
        if (this.selectedProject != null) {
            const project = this.toDisplayProject(this.document.findProjectById(this.selectedProject.id));

            if (project != null && !isEqual(this.selectedProject, project)) {
                this.selectedProject = project;
            }
        }

        this.document.recountDesignsInProjects();
    }

    private refreshProjectsAndDesigns() {
        this.refreshProjects();
        this.refreshDesigns();
        this.filterProjects();
    }

    private onDesignViewChange() {
        if (this.designView == ProjectAndDesignView.allDesigns && Object.keys(this.document.projects).length > 0) {
            if (this.user.project != null) {
                this.user.project.off(ProjectEvent.stateChanged, this.projectStateChanged);
            }

            this.selectedProject = null;
            this.user.project = Object.values(this.document.projects).find(p => p.projectType == ProjectType.draft);

            this.refreshDesigns();

            Object.values(this.document.projectsFlat).forEach(p => p.loadFromStart = true);

            this.loadPurchaserDataEntity().then(connections => {
                this.connectionsGrid.connections = connections || [];
                this.connectionsGrid.fullyLoaded = this.connectionsGrid.connections.length == Object.keys(this.document.designsFlat).length;
            });

            // add events
            for (const pKey in this.document.projects) {
                this.document.projects[pKey].on(ProjectEvent.stateChanged, this.projectStateChanged);
            }
        }

        else if (this.designView == ProjectAndDesignView.drafts) {
            if (this.user.project != null) {
                this.user.project.off(ProjectEvent.stateChanged, this.projectStateChanged);
            }

            this.selectedProject = null;
            const foundProject = this.document.findProjectById(this.document.draftsProject.id);
            this.user.project = foundProject ?? this.defaultProject;
            this.refreshDesigns();

            const project = this.user.project;

            this.connectionsGrid.lastAction = null;
            this.loadPurchaserDataEntity(true)
                .then(connections => {
                    this.connectionsGrid.connections = connections;
                    this.connectionsGrid.fullyLoaded = project.fullyLoaded;
                });

            // add events
            project.on(ProjectEvent.stateChanged, this.projectStateChanged);
        }
    }

    private userConnectionsChanged(connections: IConnection[]) {

        const designs = Object.values(this.document.projectsFlat).reduce((arr, project) => [...arr, ...((project.model[PropertyMetaData.Design_DesignConnections.id] as DesignEntity[]) ?? [])], [] as DesignEntity[]).filter(d => d != null);

        for (const connection of connections) {
            const designParts = designs.filter(purchaserDesign => purchaserDesign.DesignId == connection.id);

            if (designParts == null || designParts.length == 0) {
                throw new Error('Design not found.');
            }

            for (const design of designParts) {
                if (ConnectionsGridController.isDesignValid(design.DesignStatus)) {
                    design.AnchorMultiplier = connection.required;
                    design.IsIncluded = connection.selected;

                    // set selected size
                    let connectionArticleNumber: string = null;

                    if (design.AnchorType == null || design.AnchorType == AnchorType.Insert) {
                        connectionArticleNumber = connection.mechanicalAnchor.selectedSize || ArticleNumberNotFound;
                    }
                    else if (design.AnchorType == AnchorType.Mortar || design.AnchorType == AnchorType.Capsule) {
                        connectionArticleNumber = connection.chemicalAnchor.selectedSize || ArticleNumberNotFound;
                    }
                    else if (design.AnchorType == AnchorType.SieveSleeve) {
                        continue;
                    } // sieve sleeve cannot be changed
                    else {
                        throw new Error('Unknown AnchorType.');
                    }

                    if (connectionArticleNumber != design.SelectedAnchor.ArticleNumber) {
                        const similarAnchors = design.SelectedAnchor.SimilarAnchors.filter(similarAnchor => similarAnchor.ArticleNumber != connectionArticleNumber);
                        // Selected anchor is not included in similar anchors so we add selected with concat
                        let selectedAnchor = [...design.SelectedAnchor.SimilarAnchors, design.SelectedAnchor].find(similarAnchor => similarAnchor.ArticleNumber == connectionArticleNumber);

                        // Selected anchor might not have article number, avoiding undefined exception
                        if (selectedAnchor == null) {
                            selectedAnchor = design.SelectedAnchor;
                        }

                        design.SelectedAnchor.SimilarAnchors = [];
                        selectedAnchor.SimilarAnchors = [...similarAnchors, cloneDeep(design.SelectedAnchor)];
                        design.SelectedAnchor = selectedAnchor;
                    }
                }
            }
        }
    }

    private onConnectionsChanged(connections: IConnection[]) {
        // update summary
        this.anchorSummary = [];

        if (connections != null && connections.length > 0) {
            const anchors = [
                ...connections.map(connection => ({ anchor: connection.mechanicalAnchor, connection, anchorType: AnchorType.Insert })),
                ...connections.map(connection => ({ anchor: connection.chemicalAnchor, connection, anchorType: AnchorType.Mortar })),
                ...connections.flatMap(connection => (connection.sieveSleeveAnchors.map(anchor => ({ anchor, connection, anchorType: AnchorType.SieveSleeve }))))
            ].filter(anchor => anchor.anchor != null && anchor.connection.selected);

            const articleNumberAnchors = anchors.filter(anchor => anchor.anchor.selectedSize != null && anchor.anchor.selectedSize != '');
            const noArticleNumberAnchors = anchors.filter(anchor => anchor.anchor.selectedSize == null || anchor.anchor.selectedSize == '');

            const articleNumberGroup = groupBy(articleNumberAnchors, anchor => anchor.anchor.selectedSize);

            // article number group
            for (const articleNumber in articleNumberGroup) {
                const anchors = articleNumberGroup[articleNumber];

                const anchor = anchors[0].anchor;
                const connection = anchors[0].connection;
                const anchorType = anchors[0].anchorType;

                const size = anchor.sizes.find(size => size.articleNumber == articleNumber);
                const total = anchors.map(anchor => anchor.anchor.total).reduce((a, b) => a + b, 0);

                this.anchorSummary.push({
                    id: articleNumber,
                    designId: connection.id,
                    name: anchor.name,
                    articleNumber,
                    anchorType,
                    total: this.numberService.format(total),
                    sizeName: size.name,
                    manual: connection.manual,
                    articleQuantity: size.articleQuantity,
                    tFixMax: size.tFixMax
                });
            }

            // no article number (connections that do not have article number)
            // those should not be grouped
            for (const id in noArticleNumberAnchors) {
                const anchor = noArticleNumberAnchors[id].anchor;
                const connection = noArticleNumberAnchors[id].connection;
                const anchorType = noArticleNumberAnchors[id].anchorType;

                this.anchorSummary.push({
                    id: `anchor-id:${id}`,
                    designId: connection.id,
                    name: anchor.name,
                    anchorType,
                    total: this.numberService.format(anchor.total),
                    manual: connection.manual
                });
            }

            // sort by name
            this.anchorSummary.sort((a, b) => {
                if (a.name == null || b.name == null) {
                    return 0;
                }
                else if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                }
                else if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
        }
    }

    private getDocumentProjects() {
        return Object.values(this.document.projects || {}).filter((project) => project.id != this.document.draftsProject.id);
    }

    private sizeConnectionsGrid() {
        if (!this.destroyed) {
            const leftMenuH = document.querySelector<HTMLElement>('.navigation').offsetHeight;
            const rightMenuH = document.querySelector<HTMLElement>('.main-container-right-wrapper').offsetHeight;
            const buttonsContainerH = document.querySelector<HTMLElement>('.buttons-container').offsetHeight;
            const headerH = document.querySelector<HTMLElement>('.main-header').offsetHeight;
            const windowH = document.documentElement.clientHeight;

            const gridNode = document.querySelector<HTMLElement>('.connections-grid');

            const heightByNavigation = Math.max(leftMenuH, rightMenuH) - buttonsContainerH;
            const heightByWindow = windowH - headerH - buttonsContainerH;

            gridNode.style.height = Math.max(heightByNavigation, heightByWindow) + 'px';

            requestAnimationFrame(this.sizeConnectionsGrid);
        }
    }

    private getManageHiltiAccountUrl() {
        return this.productInformation.regionLinksForUser()?.ManageAccountLink;
    }

    private setWorkingConditionDropdownItems() {
        this.workingConditionDropdown.items = this.codeList.codelist[ProjectCodeList.WorkingCondition].map((item) =>
            new DropdownItem({
                value: item.id as WorkingCondition,
                text: this.localization.getLocalizedString(item.nameResourceKey),
            }));
    }

    private destroy() {
        if (this.localizationChangeSubscription != null) {
            this.localizationChangeSubscription.unsubscribe();
            this.localizationChangeSubscription = null;
        }

        this.user.autoSelectDesignId = null;
        this.destroyed = true;
    }
}
