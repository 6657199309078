import { IAlertParameters } from '../../src/app/entities/alert';
import { IApplicationError } from '../../src/app/entities/application-error';
import {
    DesignExternalMetaData, IDesignExternalMetaDataConstructor
} from '../../src/app/entities/design-external-meta-data';
import { ProjectUser } from '../../src/app/entities/project-user';
import {
    DocumentGetDocument, DocumentGetProject, DocumentGetResponse
} from '../../src/app/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.Documents';
import {
    ArchiveProjectResponseModel
} from '../../src/app/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.Projects';
import {
    ProjectDesignBaseEntity
} from '../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.PEDependencies';
import {
    GetNumberOfManualDesignsRequest
} from '../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Data';
import { GuidService } from '../../src/app/guid.service';
import { urlPath } from '../../src/app/ModuleConstants';
import { DateTimeService } from '../../src/app/services/date-time.service';
import { ErrorHandlerService, ModalDialogType } from '../../src/app/services/error-handler.service';
import { LocalizationService } from '../../src/app/services/localization.service';
import { LoggerService } from '../../src/app/services/logger.service';
import { environment } from '../../src/environments/environment';
import { Design, IBaseDesign } from '../Entities/Design';
import { getProjectDeps, IProjectArchive, Project } from '../Entities/Project';
import { BrowserService } from './browser-service';
import { ChangesService } from '../../src/app/services/changes.service';
import { DataService } from './data-service';
import { IRequestConfig } from './http-interceptor-service';
import { ModalService } from './modal-service';
import { PromiseService } from './promise-service';
import { TrackingService } from './tracking-service';
import { UnitService } from './unit-service';
import { ProjectAndDesignView, UserService } from './user-service';
import { UserSettingsService } from './user-settings-service';

export const enum ProjectType {
    default = 1, // the default project loaded at start
    common = 2, // a simple common project
    draft = 3 // a special draft project
}

export interface IDocumentServiceDesign {
    documentid: string;
    name: string;
    projectid: string;
}

interface IDocumentServiceRequest {
    documentId: string;
    isLock: boolean;
    key: string; // user session key
}

export interface IDocumentServiceResponse {
    documentId: string;
    filecontent: string;
    documentName: string;
}

/**
 * proxy for document service that stores documents in project hierarchy structure
 * responsible for:
 *  - all communication with document service
 *  - holding local data structures representing project, document and design structures.
 *  - creating the initial drafts project if it does not yet exist
 *  - managing session with open design documents
 *  - Managing (reading, changing, internally holding) of external design meta-data. Minimizing requests at changing meta-data, only making call when something actually changes.
 */
export interface IDocumentService {
    /**
     * Archived projects
     */
    projectsArchive: IProjectArchive[];
    /**
     * A dictionary of root level projects. Sub projects are accessible in sub projects node of the root level projects.
     */
    projects: { [id: string]: Project; };

    /**
     * The drafts folder if it exits,
     */
    draftsProject: Project;

    /**
     * True if projects are already loaded. This means that the public properties projects, projectsFlat, designsFlat, and draftProject are loaded.
     */
    projectsLoaded: boolean;

    /**
     * A dictionary of all projects no matter where in the hierarchy tree.
     */
    projectsFlat: { [id: string]: Project; };

    /**
     * A dictionary of all designs flat ( no matter in what project a design is in).
     */
    designsFlat: { [id: string]: IDesignListItem; };

    /**
     * Find the project of the design with the specified id.
     * @param designId
     */
    findProjectByDesignId(designId: string): Project;

    /**
     * Find design by id
     * @param designId
     */
    findDesignById(designId: string): IDesignListItem;

    /**
     * Find design by name
     * @param designName
     * @param projectId
     */
    findDesignByName(designName: string, projectId: string, forceFromServer?: boolean): ng.IPromise<IDesignListItem>;

    /**
     * Find project by id
     * @param projectId
     */
    findProjectById(projectId: string): Project;

    /**
     * Check is there are already projects with the same name
     * @param projectName
     * @param parentId
     * @param projectId
     */
    projectNameExists(projectName: string, parentId: string, projectId?: string): boolean;

    /**
     * Check is there are already design with the same name for a given project
     * @param projectId
     * @param designName
     */
    designNameExists(projectId: string, designName: string): boolean;

    /**
     * Creates a unique file name from a given name
     */
    createUniqueName(oldName: string, usedNames: string[], isOffline?: boolean): string;

    /**
     * This method should be only invoked by loadInitialDataInternal().
     */
    initialize(data: DocumentGetResponse, dataArchive: ArchiveProjectResponseModel[], numberOfManualDesigns: { [key: string]: number }): void;

    /*
    * Saves a new or existing document. Returns promise when the new id of the project is returned.
    */
    saveProject(project: Project, projectLevelType: ModalDialogType): ng.IPromise<any>;

    /**
     * Deletes (permanently) the project from internal store and call external document service.
     * @projectId project
     */
    removeProject(projectId: string): ng.IPromise<any>;

    /**
     * Convert (permanently) the project to company (shared) project and call external document service.
     * @projectId project
     */
    convertProject(projectId: string): ng.IPromise<any>;

    /**
     * Deletes (permanently) archived project from internal store and call external document service.
     * @projectId project
     */
    removeArchivedProject(projectId: string): ng.IPromise<any>;

    /**
     * Removes (temporarily - can be restored from the archive) the project from internal store and call external document service.
     * @projectId project
     */
    archiveProject(projectId: string): ng.IPromise<any>;

    /**
     * Restores the project from internal store and call external document service.
     * @projectId project
     */
    restoreProject(projectId: string, projectLevelType: ModalDialogType): ng.IPromise<any>;

    /**
     * Takes the design ids and returns list of document objects, representing them.
     * @param designs
     */
    openDesigns(designs: IBaseDesign[]): ng.IPromise<IDocumentServiceResponse[]>;

    /**
     * Add a completely new design
     * @param projectId - To what project are we adding this design.
     * @param design - The new design entity.
     * @param canGenerateUniqueName - If conflict happens then if this flag is set background will generate new unique name
     * @param ignoreConflict - Ignore conflict exception popup
     */
    addDesign(projectId: string, designName: string, design: any, canGenerateUniqueName: boolean, ignoreConflict: boolean): ng.IPromise<IDesignListItem>;

    /**
     * Updates a design with the new project design. Instead of using the ProjectDesignBaseEntity in design file use the ProjectDesignBaseEntity in the override parameter.
     * @param design - The design entity.
     * @param contentOverride - Ignore the content in the design parameter and store the content in this parameter to the service.
     * @param metadataOverride - Ignore the content in the design parameter and store the content in this parameter to the service as meta data.
     */
    updateDesignWithNewContent(designId: string, projectId: string, designName: string, contentOverride: ProjectDesignBaseEntity, metadataOverride: DesignExternalMetaData, unlock?: boolean): ng.IPromise<void>;

    /**
     * Removes (temporarily - can be restored from the archive) the design from internal store and call external document service.
     * @projectId project
     */
    archiveDesign(designId: string): ng.IPromise<any>;

    /**
     * Deletes (permanently) the design from internal store and call external document service.
     * @designIds list of designs
     */
    removeDesigns(designIds: string[]): ng.IPromise<any>;

    /**
     * Upload design print screen image when a design report is ready for export
     * @param designId - design id
     * @param imageContent - the design image content in base64 encoded XML format
     */
    uploadDesignImage(designId: string, imageContent: string): ng.IPromise<void>;

    /**
     * Publishes the design.
     * @param id - The design id.
     */
    publish(id: string): ng.IPromise<void>;

    /**
     * Returns users on the project
     * @param projectId
     */
    getUsersOnProjectById(projectId: string): ng.IPromise<ProjectUser[]>;

    /**
     * Adds user to the project
     * @param data
     */
    addUsersOnProjectById(data: ProjectUser): ng.IPromise<any>;

    /**
     * Removes user from the project
     * @param data
     */
    removeUsersOnProjectById(data: ProjectUser): ng.IPromise<any>;

    /**
     * Returns if user has access to project
     * @param projectId
     * @param userName
     */
    userHasAccessToProject(projectId: string): ng.IPromise<any>;

    /**
     * Remove project from client list
     * @param projectId
     */
    deleteProjectLocal(projectId: string): void;
}

/**
 * Base implementation holds members and functionalities held by local and actual service implementation.
 */
export abstract class BaseDocumentService {
    protected _pendingRequests = 0;

    protected serviceName: string;

    protected logger: LoggerService;

    // a singleton list of root projects with sub projects stored as sub nodes
    protected _projects: { [id: string]: Project; } = {};

    protected _projectsArchive: IProjectArchive[] = [];

    protected _draftsProject: Project = null;

    protected _projectsLoaded = false;

    // a singleton flat list of all projects
    protected _projectsFlat: { [id: string]: Project; } = {};

    protected _designsFlat: { [id: string]: IDesignListItem; } = {};

    // a singleton get projects promise
    protected _projectsPromise: ng.IPromise<Project[]> = null;

    protected _manualDesignsCount: { [key: string]: number } = {};

    protected _projectItemCount: { [key: string]: number } = {};

    protected _overviewItemCount: { [key: string]: number } = {};

    constructor(serviceName: string, logger: LoggerService) {
        this.serviceName = serviceName;

        this.logger = logger;
    }
    public get pendingRequests() {
        return this._pendingRequests;
    }

    /**
     * A dictionary of root level projects. Sub projects are accessible in sub projects node of the root level projects.
     */
    public get projects(): { [id: string]: Project; } {
        return this._projects;
    }

    public get projectsArchive(): IProjectArchive[] {
        return this._projectsArchive;
    }

    /**
     * The drafts folder if it exits,
     */
    public get draftsProject(): Project {
        return this._draftsProject;
    }

    /**
     * True if projects are already loaded. This means that the public properties projects, projectsFlat, designsFlat, and draftProject are loaded.
     */
    public get projectsLoaded(): boolean {
        return this._projectsLoaded;
    }

    /**
     * A dictionary of all projects no matter where in the hierarchy tree.
     */
    public get projectsFlat(): { [id: string]: Project; } {
        return this._projectsFlat;
    }

    /**
     * A dictionary of dictionary designs flat ( no matter in what project a design is in).
     */
    public get designsFlat(): { [id: string]: IDesignListItem; } {
        return this._designsFlat;
    }

    /**
     * A dictionary of manual designs count.
     */
    public get manualDesignsCount(): { [key: string]: number } {
        return this._manualDesignsCount;
    }

    /**
     * A dictionary of project items count.
     */
    public get projectItemCount(): { [key: string]: number } {
        return this._projectItemCount;
    }

    /**
     * A dictionary of overview items count.
     */
    public get overviewItemCount(): { [key: string]: number } {
        return this._overviewItemCount;
    }

    /**
     * Find the project of the design with the specified id.
     * @param designId
     */
    public findProjectByDesignId(designId: string) {
        return this.findProjectByDesignIdInternal(this.projectsFlat, designId);
    }

    /**
     * Find design by id.addDesign
     * @param designId
     */
    public findDesignById(designId: string) {
        return this.findDesignByIdInternal(this.projectsFlat, designId);
    }

    /**
     * Find project by id.
     * @param projectId
     */
    public findProjectById(projectId: string) {
        return this._projectsFlat[projectId];
    }

    /**
     * Check is there are already project with the same name
     * @param projectName
     * @param parentId
     * @param projectId
     */
    public projectNameExists(projectName: string, parentId: string, projectId?: string) {
        return Object.values(this._projectsFlat).some((p) => p.name.toLowerCase().trim() == projectName.toLowerCase().trim() &&
            (p.parentId == parentId || p.id == parentId) &&
            (projectId == null || p.id != projectId));
    }

    /**
     * Check is there are already design with the same name for a given project
     */
    public designNameExists(projectId: string, designName: string) {
        return Object.values(this._designsFlat).some((d) => d.projectId == projectId && d.designName.toLowerCase().trim() == designName.toLowerCase().trim());
    }

    /**
     * Creates a unique file name from a given name
     */
    public createUniqueName(oldName: string, usedNames: string[], isOffline?: boolean) {
        if (isOffline) {
            return oldName;
        }

        let newName = oldName;
        let index = 0;

        while (usedNames.includes(newName)) {
            index++;
            newName = oldName + ' (' + index + ')';
        }

        return newName;
    }

    /**
     * Copy the parameters of the designPresentation entity (used for displaying design info on lists or for communication with document service to the full design entity.
     * @param wholeDesign
     * @param designPresentation
     */
    protected setDesignPropertiesFromServiceRecordToWholeDesignEntitiy(wholeDesign: Design, designPresentation: IDesignListItem): void {
        wholeDesign.id = designPresentation.id;
        wholeDesign.createDate = designPresentation.createDate;
        wholeDesign.designName = designPresentation.designName;
        wholeDesign.projectId = designPresentation.projectId;
        wholeDesign.projectName = designPresentation.projectName;
        wholeDesign.changeDate = designPresentation.changeDate;
        wholeDesign.locked = designPresentation.locked;
        wholeDesign.lockedUser = designPresentation.lockedUser;
    }

    protected logServiceRequest(fnName: string, ...args: any[]) {
        this.logger.logServiceRequest(this.serviceName, fnName, ...args);
    }

    protected logServiceResponse(fnName: string, ...args: any[]) {
        this.logger.logServiceResponse(this.serviceName, fnName, ...args);
    }

    private findProjectByDesignIdInternal(projects: { [id: string]: Project; }, designId: string): Project {
        if (projects == null) {
            return null;
        }

        return Object.values(projects).find((p) => {
            return Object.values(p.directChildDesigns).find((d) => d.id == designId);
        });
    }

    private findDesignByIdInternal(projects: { [id: string]: Project; }, designId: string): IDesignListItem {
        if (projects == null) {
            return null;
        }

        for (const pKey in projects) {
            const foundDesign = Object.values(projects[pKey].directChildDesigns).find((design) => design.id == designId);

            if (foundDesign != null) {
                return foundDesign;
            }
        }

        return null;
    }
}

class DesignSessionPair {
    /**
     * design Id
     */
    public designId: string;

    /**
     * Session id of the design.
     */
    public sessionId: string;

    /*
     * This session was explicitly canceled.
     */
    public isCancelled = false;
}

/**
 *  The interface for basic list items.
 */
export interface IDesignListItem extends IBaseDesign {
    metaData: DesignExternalMetaData;

}

/**
 * A base class for all can't perform action responses.
 */
export abstract class CantPerformActionReason {

}

/**
 * Instance of class derived from this class are sometimes be returned when opening a design instead of the design.
 */
export abstract class CantOpenDesignReason extends CantPerformActionReason {

}

/**
 * CantOpen design because another user has locked it.
 */
export class CantOpenDesignBecauseLockedByOtherUser extends CantOpenDesignReason {
    /**
     * User-name of the user that locked the design.
     */
    public username: string;
}

/**
 * Cant archive design because another user has locked it.
 */
export class CantArchiveDesignBecauseLockedByOtherUser extends CantOpenDesignReason {
    /**
     * User-name of the user that locked the design.
     */
    public username: string;
}

/**
 * An exception indicating that projects can't be deleted because they are in use or locked.
 */
export class CantDeleteProjectsBecauseDocumentInUse extends CantPerformActionReason {
    /**
     * A list of documents locked together with document names and users that have the files locked.
     */
    public documentsInQuestion: CantPerformActionReasonProblematicDocumentInformation[];
}

/**
 * An exception indicating that projects can't be deleted because they are in use or locked.
 */
export class CantRestoreProjectsBecauseDocumentInUse extends CantPerformActionReason {
    /**
     * A list of documents locked together with document names and users that have the files locked.
     */
    public documentsInQuestion: CantPerformActionReasonProblematicDocumentInformation[];
}

/**
 * An exception indicating that projects can't be deleted because they are in use or locked.
 */
export class CantArchiveProjectsBecauseDocumentInUse extends CantPerformActionReason {
    /**
     * A list of documents locked together with document names and users that have the files locked.
     */
    public documentsInQuestion: CantPerformActionReasonProblematicDocumentInformation[];
}

/**
 * Just holding information for each locked document that cussed the problem
 */
export class CantPerformActionReasonProblematicDocumentInformation {
    public designId: string;
    public username: string;
    public document: string;
}

interface IGetDocumentResponse {
    documentid: string;
    project: IGetDocumentResponseProject;
    name: string;
    lastchange: number;
    created: number;
    locked: boolean;
    lockedby: string;
    metadata: { key: string, value: string }[];
}

interface IGetDocumentResponseProject {
    projectid: string;
    name: string;
}

interface IDefferedData {
    designId: string;
    projectId: string;
    designName: string;
    contentOverride: ProjectDesignBaseEntity;
    metadataOverride: DesignExternalMetaData;
    unlock: boolean;
}

interface IDefferedRequests {
    [designId: string]: {
        defferedData: IDefferedData,
        deffered: ng.IDeferred<any>,
        runningDeffered: ng.IDeferred<any>,
        running: ng.IPromise<void>,
    };
}

/**
 * proxy for document service that stores documents in project hierarchy structure
 * responsible for:
 *  - all communication with document service
 *  - holding local data structures representing project, document and design structures.
 *  - creating the initial drafts project if it does not yet exist
 *  - managing session with open design documents
 *  - Managing (reading, changing, internally holding) of external design meta-data. Minimizing requests at changing meta-data, only making call when something actually changes.
 */
export class DocumentService extends BaseDocumentService implements IDocumentService {
    public static $inject = [
        '$http',
        'user',
        '$q',
        'logger',
        'guid',
        '$rootScope',
        'localization',
        'modal',
        '$location',
        'browser',
        'errorHandler',
        'tracking',
        'changes',
        'userSettings',
        'promise',
        'dateTime',
        'unit'
    ];

    private $http: ng.IHttpService;
    private user: UserService;
    private $q: ng.IQService;
    private guid: GuidService;
    private $rootScope: ng.IRootScopeService;
    private localization: LocalizationService;
    private modal: ModalService;
    private $location: ng.ILocationService;
    private browser: BrowserService;
    private onServiceErrorHandler: ErrorHandlerService;
    private defferedRequests: IDefferedRequests;
    private tracking: TrackingService;
    private data: DataService;
    private changes: ChangesService;
    private userSettings: UserSettingsService;
    private promise: PromiseService;
    private dateTime: DateTimeService;
    private unitService: UnitService;

    private internalRawProject: DocumentGetResponse;

    // holds instances of all open sessions (should be just one really).
    private _designSessionMapping: DesignSessionPair[] = [];

    constructor(
        $http: ng.IHttpService,
        user: UserService,
        $q: ng.IQService,
        logger: LoggerService,
        guid: GuidService,
        $rootScope: ng.IRootScopeService,
        localization: LocalizationService,
        modal: ModalService,
        $location: ng.ILocationService,
        browser: BrowserService,
        errorHandler: ErrorHandlerService,
        tracking: TrackingService,
        changes: ChangesService,
        userSettings: UserSettingsService,
        promise: PromiseService,
        dateTime: DateTimeService,
        unitService: UnitService
    ) {
        super('DocumentService', logger);

        this.$http = $http;
        this.user = user;
        this.$q = $q;
        this.guid = guid;
        this.$rootScope = $rootScope;
        this.localization = localization;
        this.modal = modal;
        this.$location = $location;
        this.browser = browser;
        this.onServiceErrorHandler = errorHandler;
        this.tracking = tracking;
        this.changes = changes;
        this.userSettings = userSettings;
        this.promise = promise;
        this.dateTime = dateTime;
        this.unitService = unitService;

        this.defferedRequests = {};
    }

    private get documentServicePublicUrlBase() {
        return `${environment.documentWebServiceUrl}document-service-legacy/public/`;
    }

    private get projectDeps() {
        return getProjectDeps(
            this.changes,
            this.localization,
            this.$http,
            this.logger,
            this,
            this.$q,
            this.$rootScope,
            this.guid,
            this.promise,
            this.dateTime,
            this.onServiceErrorHandler,
            this.modal,
            this.browser,
            this.userSettings,
            this.data,
            this.tracking,
            this.unitService
        );
    }

    /**
     * Deletes (permanently) the project from internal store and call external document service.
     * @projectId project
     */
    public removeArchivedProject(projectId: string): ng.IPromise<any> {
        const pToDeleteCandidate = this._projectsArchive.find((item) => item.id == projectId);
        if (pToDeleteCandidate == null) {
            throw new Error('Project with ID does not exist.');
        }

        return this.removeExistingArchivedProject(projectId)
            .then(x => {
                // remove from project flat list of projects
                this._projectsArchive = this._projectsArchive.filter((x) => x.id != projectId);
            })
            .catch(ex => {
                // catch exception code that happens when project cannot be deleted because a file in the project is locked, and fetch additional details from the document service to display to the user which document is locked and by whom.
                if (ex instanceof CantDeleteProjectsBecauseDocumentInUse) {
                    const cantEx: CantDeleteProjectsBecauseDocumentInUse = ex as CantDeleteProjectsBecauseDocumentInUse;
                    return this.readLockedDesignsOfProject(projectId)
                        .then<CantDeleteProjectsBecauseDocumentInUse>(lockedFiles => {
                            cantEx.documentsInQuestion = lockedFiles;

                            return this.$q.reject(cantEx);
                        });

                }

                return this.$q.reject(ex);
            });
    }

    /**
     * Convert (permanently) the project to company (shared) project and call external document service.
     * @projectId project
     */
    public convertProject(projectId: string): ng.IPromise<any> {
        const pToConvertCandidate = this._projectsFlat[projectId];
        if (pToConvertCandidate == null) {
            throw new Error('Project with ID does not exist.');
        }

        return this.convertExistingProject(projectId)
            .then(x => {
                // convert project from list of projects
                if (pToConvertCandidate.parentId != null) {
                    Object.values(Object.values(this._projects).find((x) => x.id == pToConvertCandidate.parentId).subProjects).find((item) => item.id == projectId).isCompanyProject = true;
                }
                else if (pToConvertCandidate.subProjects != null && Object.keys(pToConvertCandidate.subProjects).length > 0) {
                    Object.values(Object.values(this._projects).find((x) => x.id == pToConvertCandidate.id).subProjects).find((item) => item.parentId == projectId).isCompanyProject = true;
                    Object.values(this._projects).find((x) => x.id == pToConvertCandidate.id).isCompanyProject = true;
                }
                else {
                    Object.values(this._projects).find((x) => x.id == pToConvertCandidate.id).isCompanyProject = true;
                }
            })
            .catch(ex => {
                return this.$q.reject(ex);
            });
    }

    /**
     * Deletes (permanently) the project from internal store and call external document service.
     * @projectId project
     */
    public removeProject(projectId: string): ng.IPromise<any> {
        const pToDeleteCandidate = Object.values(this._projectsFlat).find((item) => item.id == projectId);
        if (pToDeleteCandidate == null) {
            throw new Error('Project with ID does not exist.');
        }

        return this.removeExistingProject(projectId)
            .then(x => {
                // remove project from list of projects
                if (pToDeleteCandidate.parentId != null) {
                    delete Object.values(this._projects).find((x) => x.id == pToDeleteCandidate.parentId).subProjects[projectId];
                }
                else {
                    delete this._projects[projectId];
                }

                // remove project from local list
                this.deleteProjectLocal(projectId);
            })
            .catch(ex => {
                // catch exception code that happens when project cannot be deleted because a file in the project is locked, and fetch additional details from the document service to display to the user which document is locked and by whom.
                if (ex instanceof CantDeleteProjectsBecauseDocumentInUse) {
                    const cantEx: CantDeleteProjectsBecauseDocumentInUse = ex as CantDeleteProjectsBecauseDocumentInUse;
                    return this.readLockedDesignsOfProject(projectId)
                        .then<CantDeleteProjectsBecauseDocumentInUse>(lockedFiles => {
                            cantEx.documentsInQuestion = lockedFiles;

                            return this.$q.reject(cantEx);
                        });

                }

                return this.$q.reject(ex);
            });
    }

    /**
     * Restores the project from internal store and call external document service.
     * @projectId project
     */
    public restoreProject(projectId: string, projectLevelType: ModalDialogType): ng.IPromise<any> {
        const pToRestoreCandidate = Object.values(this._projectsArchive).find((item) => item.id == projectId);
        if (pToRestoreCandidate == null) {
            throw new Error('Project with ID does not exist.');
        }

        return this.restoreExistingProject(projectId, projectLevelType)
            .then(x => {
                // Add restored project and it's documents to the project menu
                if (x != null && x.Projects != null && x.Documents != null) {
                    for (const key in x.Projects) {
                        this.internalRawProject.Projects[key] = x.Projects[key];
                    }
                    for (const key in x.Documents) {
                        this.internalRawProject.Documents[key] = x.Documents[key];
                    }

                    this.getInternal(this.internalRawProject);
                }
                // Remove project and all sub-projects from archive list
                this._projectsArchive = this._projectsArchive.filter(x => x.id != projectId && x.parentId != projectId);
            })
            .catch(ex => {
                // catch exception code that happens when project cannot be deleted because a file in the project is locked, and fetch additional details from the document service to display to the user which document is locked and by whom.
                if (ex instanceof CantRestoreProjectsBecauseDocumentInUse) {
                    const cantEx: CantRestoreProjectsBecauseDocumentInUse = ex as CantRestoreProjectsBecauseDocumentInUse;
                    return this.readLockedDesignsOfProject(projectId)
                        .then<CantRestoreProjectsBecauseDocumentInUse>(lockedFiles => {
                            cantEx.documentsInQuestion = lockedFiles;

                            return this.$q.reject(cantEx);
                        });

                }

                return this.$q.reject(ex);
            });
    }

    public initialize(data: DocumentGetResponse, dataArchive: ArchiveProjectResponseModel[], numberOfManualDesigns: { [key: string]: number }) {
        this.internalRawProject = { Projects: {}, Documents: {} };
        this.getInternal(data);

        this._manualDesignsCount = numberOfManualDesigns;
        this.recountDesignsInProjects();

        this.getArchiveInternal(dataArchive);
    }

    /*
    * Saves a new or existing document. Returns promise when the new id of the project is returned.
    */
    public saveProject(project: Project, projectLevelType: ModalDialogType): ng.IPromise<any> {
        if (project.name.indexOf('#$') == 0) {
            const param: IAlertParameters = {
                title: this.localization.getLocalizedString('Agito.Hilti.Purchaser.DocumentService.Alerts.IllegalChar.Title'),
                message: this.localization.getLocalizedString('Agito.Hilti.Purchaser.DocumentService.Alerts.IllegalChar.Message')
            };
            this.modal.alert.open(param);

            return this.$q.reject('invalid-name');
        }
        else {
            if (project.name.length > 250) {
                throw new Error('Max length for project name is 250 characters!');
            }

            if (Object.values(this._projectsFlat).some((p) => p.id == project.id)) {
                // call edit project on document service
                return this.updateExistingProject(project, projectLevelType)
                    .then(() => {
                        const internalProject = this.findProjectById(project.id);
                        internalProject.name = project.name;
                    });
            }
            else {
                // call add new project on document service
                const promise = this.addNewProject(project, projectLevelType)
                    .then(() => {
                        this.internalRawProject.Projects[project.id] = ({
                            Id: project.id,
                            Name: project.name,
                            DateChanged: new Date(),
                            DateCreated: new Date(),
                            ParentId: project.parentId,
                            Owner: project.owner,
                            IsCompanyProject: project.isCompanyProject,
                            ReadOnly: project.readOnly
                        } as DocumentGetProject);
                        this._projectsFlat[project.id] = project;
                        if (project.parentId != null) {
                            this._projects[project.parentId].subProjects[project.id] = project;
                        }
                        else {
                            this._projects[project.id] = project;
                        }
                    });

                return promise;
            }
        }
    }

    /**
     * Remove the project from internal store and call external document service.
     * @projectId project
     */
    public archiveProject(projectId: string): ng.IPromise<any> {
        const pToDeleteCandidate = this.findProjectById(projectId);

        if (pToDeleteCandidate == null) {
            throw new Error('Project with ID does not exist.');
        }
        if (pToDeleteCandidate.projectType != ProjectType.common) {
            throw new Error('Only common projects can be deleted.');
        }

        // delete parent
        return this.archiveExistingProject(projectId)
            .then(resp => {
                const children: string[] = [];
                for (const index in resp) {
                    const x = resp[index];
                    const projId = x.projectid;
                    this._projectsArchive.push({
                        archived: x.archived,
                        created: x.created,
                        designs: x.designs + (this._manualDesignsCount[x.projectid] || 0),
                        members: x.members,
                        id: x.projectid,
                        parentId: x.parentprojectid,
                        name: x.name
                    } as IProjectArchive);

                    this.deleteProjectFunc(projId);
                }
            })
            .catch(ex => {
                // catch exception code that happens when project cannot be deleted because a file in the project is locked, and fetch additional details from the document service to display to the user which document is locked and by whom.
                if (ex instanceof CantArchiveProjectsBecauseDocumentInUse) {
                    const cantEx: CantArchiveProjectsBecauseDocumentInUse = ex as CantArchiveProjectsBecauseDocumentInUse;
                    return this.readLockedDesignsOfProject(projectId)
                        .then<CantArchiveProjectsBecauseDocumentInUse>(lockedFiles => {
                            cantEx.documentsInQuestion = lockedFiles;

                            return this.$q.reject(cantEx);
                        });
                }

                return this.$q.reject(ex);
            });
    }

    /**
     * Takes the designIds and returns list of document objects, representing them.
     * @param designs
     */
    public openDesigns(designs: IBaseDesign[]): ng.IPromise<IDocumentServiceResponse[]> {
        const designIds = designs.map(({ id }) => id);
        return this.getDesignContentExclusives(designIds)
            .catch(response => {
                return this.$q.reject(response);
            });
    }

    /**
     * Add a completely new design
     * @param projectId - To what project are we adding this design.
     * @param design - The new design entity.
     */
    public addDesign(projectId: string, designName: string, design: any, canGenerateUniqueName: boolean, ignoreConflict: boolean) {

        // validate
        const project = this.findProjectById(projectId);
        if (project == null) {
            throw new Error('Invalid project ID.');
        }

        if (designName.length > 250) {
            throw new Error('Max length for design name file is 250 !');
        }

        let metaData: any[];

        // conversion
        return this.browser.toBase64(design)
            .then((designAsBase64Endoded) => {
                metaData = this.getDocumentMetaData(designAsBase64Endoded);

                return this.addNewFile(project, designName, designAsBase64Endoded, metaData, canGenerateUniqueName, ignoreConflict);   // call service to add new file into database
            })
            .then((document: IDocumentServiceDesign) => {
                this.openNewSessionForDesign(document.documentid);

                // add returned data to internal memory and return new design as promised
                const now: Date = new Date();
                const nowUtc: Date = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds()));
                const parsedMetaData = new DesignExternalMetaData({
                    region: parseInt(metaData['region']),
                    standard: parseInt(metaData['standard']),
                    designMethod: parseInt(metaData['designMethod']),
                    designType: parseInt(metaData['designType'])
                } as IDesignExternalMetaDataConstructor);
                const newDesign: IDesignListItem = {
                    id: document.documentid,
                    changeDate: nowUtc,
                    createDate: nowUtc,
                    designName: document.name,
                    locked: false,
                    lockedUser: null,
                    projectId,
                    projectName: '',
                    metaData: parsedMetaData
                };

                // associate design to project
                const project = this.findProjectById(projectId);
                if (project == null) {
                    throw new Error('Invalid project ID.');
                }

                newDesign.projectName = project.name;
                project.directChildDesigns[newDesign.id] = newDesign;
                project.designs[newDesign.id] = newDesign;

                // add design to all parent designs
                let parentX: Project = this.findProjectById(project.parentId);
                while (parentX != undefined && parentX != null) {
                    parentX.designs[newDesign.id] = newDesign;
                    parentX = this.findProjectById(parentX.parentId);
                }

                // add new design to designs in general
                this.internalRawProject.Documents[newDesign.id] = {
                    Id: newDesign.id,
                    Name: newDesign.designName,
                    ProjectId: newDesign.projectId,
                    Type: null,
                    DateCreated: newDesign.createDate,
                    DateChanged: newDesign.changeDate,
                    Locked: newDesign.locked,
                    LockedUserName: newDesign.lockedUser,
                    Metadata: newDesign.metaData as any,
                    Owner: false    // XXX: @JanezB: check how this is done in pe-ui
                };
                this._designsFlat[newDesign.id] = newDesign;

                // this.setDesignPropertiesFromServiceRecordToWholeDesignEntitiy(design, newDesign);

                return newDesign;
            });
    }

    /**
     * Updates a design with the new project design.
     * @param design - The design entity.
     */
    // public updateDesign(design: Design): ng.IPromise<void> {
    //    let metaData: DesignExternalMetaData = DesignExternalMetaData.getMetaDataFromDesign(design);
    //    return this.updateDesignWithNewContent(design.id, design.projectId, design.designName, design.projectDesign, metaData);
    // }

    /**
     * Updates a design with the new project design. Instead of using the ProjectDesignBaseEntity in design file use the ProjectDesignBaseEntity in the override parameter.
     * @param design - The design entity.
     * @param contentOverride - Ignore the content in the design file and store the content in this parameter to the service.
     * @param metadataOverride - Ignore the content in the design parameter and store the content in this parameter to the service as meta data.
     */
    public updateDesignWithNewContent(designId: string, projectId: string, designName: string, contentOverride: ProjectDesignBaseEntity, metadataOverride: DesignExternalMetaData, unlock = false) {
        // If there are no pending requests start normally
        if (this.defferedRequests[designId] == null || (this.defferedRequests[designId] != null && this.defferedRequests[designId].running == null)) {
            this.defferedRequests[designId] = {
                running: this.putSmallDesignChangePromise({ designId, projectId, designName, contentOverride, metadataOverride, unlock } as IDefferedData),
                runningDeffered: null,
                defferedData: null,
                deffered: null
            };
            return this.defferedRequests[designId].running;
        }
        else {
            // Else create deffer object save request data and return deferred promise
            if (this.defferedRequests[designId].deffered == null) {
                this.defferedRequests[designId].deffered = this.$q.defer();
            }

            this.defferedRequests[designId].defferedData = {
                designId,
                projectId,
                designName,
                contentOverride,
                metadataOverride,
                unlock
            };

            return this.defferedRequests[designId].deffered.promise as ng.IPromise<void>;
        }
    }

    /**
     * Remove the design from internal store and call external document service.
     * @designId design
     */
    public archiveDesign(designId: string): ng.IPromise<any> {

        const dToDeleteCandidate = this.findDesignById(designId);
        if (dToDeleteCandidate == null) {
            throw new Error('Design with ID does not exist.');
        }

        // delete design
        return this.archiveExistingDesign(designId)
            .then(resp => {
                this.deleteDesignFunc(designId);
            })
            .catch(ex => {
                // catch exception code that happens when design cannot be deleted because a file is locked, and fetch additional details from the document service to display to the user which document is locked and by whom.
                if (ex instanceof CantArchiveDesignBecauseLockedByOtherUser) {
                    const cantEx: CantArchiveDesignBecauseLockedByOtherUser = ex as CantArchiveDesignBecauseLockedByOtherUser;
                    return this.getDesignBasicDocumentInformation(designId)
                        .then<CantArchiveDesignBecauseLockedByOtherUser>(d => {
                            cantEx.username = d.lockedUser;

                            return this.$q.reject(cantEx);
                        });
                }

                return ex;
            });
    }

    /**
     * Deletes (permanently) the designs from internal store and call external document service.
     * @designIds list of designs
     */
    public removeDesigns(designIds: string[]): ng.IPromise<any> {

        for (const designId of designIds) {
            const dToDeleteCandidate = this.findDesignById(designId);
            if (dToDeleteCandidate == null) {
                throw new Error(`Design with ID ${designId} does not exist.`);
            }
        }

        // delete designs
        return this.removeExistingDesigns(designIds)
            .then(resp => {
                for (const designId of designIds) {
                    this.deleteDesignFunc(designId);
                }
            });
    }

    /**
     * Upload design print screen image when a design report is ready for export
     * @param designId - design id
     * @param imageContent - the design image content in base64 encoded XML format
     */
    public uploadDesignImage(designId: string, imageContent: string) {
        return this.uploadDesignImageInternal(designId, imageContent, this.getSessionKeyForDesign(designId));
    }

    /**
     * Publishes the design.
     * @param id - The design id.
     */
    public publish(id: string): ng.IPromise<void> {
        if (!this.isCanceledSessionForDesign(id)) {
            return this.postUnlockDocument(id)
                .then(x => {
                    this.closeSessionForDesign(id);
                });
        }
        else {
            return this.$q.when();
        }
    }

    /**
     * Returns users on the project
     * @param projectId
     */
    public getUsersOnProjectById(projectId: string): ng.IPromise<ProjectUser[]> {
        return this.getUsersOnProjectByIdInternal(projectId)
            .then<ProjectUser[]>((response) => {
                return response.map((item) => {
                    item.projectid = projectId;
                    item.dateadded = item.dateadded != null ? new Date(item.dateadded as any) : item.dateadded;
                    return item;
                });
            });
    }

    /**
     * Adds user to the project
     * @param data
     */
    public addUsersOnProjectById(data: ProjectUser): ng.IPromise<any> {
        return this.addUsersOnProjectByIdInternal(data);
    }

    /**
     * Removes user from the project
     * @param data
     */
    public removeUsersOnProjectById(data: ProjectUser): ng.IPromise<any> {
        return this.removeUsersOnProjectByIdInternal(data);
    }

    public userHasAccessToProject(projectId: string): ng.IPromise<any> {
        return this.userHasAccessToProjectInternal(projectId);
    }

    public deleteProjectLocal(projectId: string) {
        Object.values(this.internalRawProject.Projects).forEach((proj) => {
            if (proj.ParentId == projectId) {
                this.deleteProjectFunc(proj.Id);
            }
        });

        this.deleteProjectFunc(projectId);
    }

    /**
     * Find design by name.
     * @param designName
     * @param projectId
     */
    public findDesignByName(designName: string, projectId: string, forceFromServer?: boolean) {
        return this.findDesignByNameInternal(designName, projectId, forceFromServer);
    }

    public loadNumberOfDesignsPerProject() {
        const url = `${environment.purchaserApplicationWebServiceUrl}GetNumberOfManualDesigns`;
        const config = { projectIds: Object.values(this._projectsFlat).map(p => p.id) } as GetNumberOfManualDesignsRequest;

        return this.$http.post(url, config).then(response => response.data as { [key: string]: number })
            .then(manualDesigns => { this._manualDesignsCount = manualDesigns; this.recountDesignsInProjects(); });
    }

    public recountDesignsInProjects() {
        const draftProject = Object.values(this._projectsFlat).find(p => p.projectType == ProjectType.draft);

        const projects = Object.values(this._projectsFlat).reduce((o, p) => {
            o[p.id] = { count: 0, parentId: p.parentId || null };
            return o;
        }, {});

        // count designs from document service
        Object.values(this._designsFlat).forEach((d) => {
            if (projects[d.projectId] != null) {
                projects[d.projectId].count++;

                // add to parent
                if (projects[d.projectId].parentId != null && projects[projects[d.projectId].parentId] != null) {
                    projects[projects[d.projectId].parentId].count++;
                }
            }
        });

        // count manual designs
        Object.entries(this._manualDesignsCount).forEach(([key, count]) => {
            if (projects[key]) {
                projects[key].count += count;

                // add to parent
                if (projects[key].parentId != null && projects[projects[key].parentId] != null) {
                    projects[projects[key].parentId].count += count;
                }
            }
        });

        this._projectItemCount = Object.entries(projects).reduce((o, [k, p]: [string, { count: number }]) => { o[k] = p.count; return o; }, {} as { [key: string]: number });
        this._overviewItemCount[ProjectAndDesignView.allDesigns] = Object.values(projects).reduce<number>((sum, p: { count: number, parentId: string }) => (p.parentId == null) ? sum += p.count : sum, 0);
        this._overviewItemCount[ProjectAndDesignView.drafts] = projects[draftProject.id].count;
        this._overviewItemCount[ProjectAndDesignView.projects] = this._projectItemCount != null ? Object.keys(this._projectItemCount).length : 0;
    }

    private mapArchiveProjectResponseEntityToIProjectArchive(row: ArchiveProjectResponseModel) {
        return {
            archived: row.Archived,
            created: row.Created,
            id: row.ProjectId,
            designs: row.Designs + (this._manualDesignsCount[row.ProjectId] || 0),
            members: row.Members,
            name: row.Name,
            parentId: row.ParentProjectId
        } as IProjectArchive;
    }

    private getArchiveInternal(dataArchive: ArchiveProjectResponseModel[]) {
        this._projectsArchive = [];

        for (const item in dataArchive) {
            this._projectsArchive.push(this.mapArchiveProjectResponseEntityToIProjectArchive(dataArchive[item]));
        }
    }

    private getInternal(data: DocumentGetResponse) {
        this.internalRawProject = data;
        this._designsFlat = {};
        this._draftsProject = null;
        this._projectsFlat = {};
        this._projects = {};

        interface IResponse {
            projects: { [projectId: string]: IProject };
            documents: { [documentId: string]: IDocument };
        }

        interface IProject {
            id: string;
            name: string;
            parentId: string;
            dateChanged: string;
            dateCreated: string;
            owner: boolean;
            isCompanyProject: boolean;
            readOnly: boolean;
        }

        interface IDocument {
            id: string;
            name: string;
            projectId: string;
            dateChanged: string;
            dateCreated: string;
            locked: boolean;
            lockedUserName: string;
            type: number;
            metadata: { [key: string]: string };
        }

        let projects: { [projectId: string]: Project } = {};
        if (data != null && data.Projects != null) {
            projects = Object.fromEntries(Object.entries(data.Projects).map(([id, project]) =>
                [id, new Project(this.projectDeps,
                    {
                        id: project.Id,
                        name: this.getProjectName(project.Name),
                        parentId: project.ParentId,
                        projectType: this.getProjectType(project.Name),
                        designs: null,
                        subProjects: null,
                        changeDate: new Date(project.DateChanged.toString()),
                        createDate: new Date(project.DateCreated.toString()),
                        owner: project.Owner,
                        isCompanyProject: project.IsCompanyProject,
                        readonly: project.ReadOnly
                    })]));
        }

        let documents: { [documentId: string]: IDesignListItem } = {};
        if (data != null && data.Documents != null) {
            documents = Object.fromEntries(Object.entries(data.Documents).map(([id, document]) =>
                [id, {
                    id: document.Id,
                    projectId: document.ProjectId,
                    projectName: projects[document.ProjectId].name,
                    changeDate: new Date(document.DateChanged.toString()),
                    createDate: new Date(document.DateCreated.toString()),
                    designName: document.Name,
                    locked: document.Locked,
                    lockedUser: document.LockedUserName,
                    metaData: this.getDesignExternalMetadata(document.Metadata)
                } as IDesignListItem]));
        }

        this._designsFlat = documents;
        const allSubProjects = Object.values(projects).filter(projects => projects.parentId != null);
        const allParentProjects = Object.values(projects).filter(projects => projects.parentId == null);

        // add designs to projects
        for (const project of allParentProjects) {
            const designs: { [id: string]: IDesignListItem } = Object.fromEntries(Object.entries(documents).filter(([id, document]) => document.projectId == project.id));
            project.directChildDesigns = designs;
            project.designs = designs;

            this._projectsFlat[project.id] = project;
            this._projects[project.id] = project;

            // drafts project
            if (project.projectType == ProjectType.draft) {
                this._draftsProject = project;
            }
        }

        // add designs to subProjects
        for (const project of allSubProjects) {
            // Skip sub project that are created on level 2 or deeper. QC does not support more than 2 levels of projects (new home page functionality).
            // All designs from sub projects are moved to parent project.
            if (allSubProjects.includes(projects[project.parentId])) {
                const appendDesignsToParent = (projects: { [id: string]: Project }, projectId: string, designs: { [id: string]: IDesignListItem; }) => {
                    const project = projects[projectId];

                    if (project.parentId === null || projects[project.parentId] === undefined) {
                        Object.entries(designs).forEach(([id, design]) => { design.projectId = projectId });
                        if(this._projects[project.id] != undefined){
                            this._projects[project.id].designs = { ...this._projects[project.id].designs, ...designs };
                        }
                    } else {
                        appendDesignsToParent(projects, project.parentId, designs);
                    }
                }
                const designs: { [id: string]: IDesignListItem } = Object.fromEntries(Object.entries(documents).filter(([id, document]) => document.projectId == project.id));
                appendDesignsToParent(projects, project.parentId, designs);
                continue;
            }

            const designs: { [id: string]: IDesignListItem } = Object.fromEntries(Object.entries(documents).filter(([id, document]) => document.projectId == project.id));
            project.directChildDesigns = designs;
            project.designs = designs;

            this._projectsFlat[project.id] = project;
            if (this._projects[project.parentId] != null) {
                this._projects[project.parentId].subProjects[project.id] = project;
            }
            else {
                this._projects[project.id] = project;
            }

        }

        // drafts project
        if (!Object.values(this._projectsFlat).some((project) => project.projectType == ProjectType.draft)) {
            const draftProject: Project = new Project(this.projectDeps, { name: '#$draft', projectType: ProjectType.draft });

            // call the actual service too add the project into store
            this.addNewProject(draftProject, ModalDialogType.project).then((project: Project) => {
                this._projectsFlat[project.id] = project;
                this._projects[project.id] = project;
                this._draftsProject = project;
            });
        }
    }

    private getProjectType(projectName: string) {
        if (projectName != null && projectName.indexOf('#$') == 0) {
            const projectType = projectName.substr(2);

            switch (projectType) {
                case 'draft':
                    return ProjectType.draft;
            }
        }

        return ProjectType.common;
    }

    private getProjectName(projectName: string) {
        if (projectName != null && projectName.indexOf('#$') == 0) {
            const projectType = projectName.substr(2);
            return this.localization.getLocalizedString(`Agito.Hilti.Purchaser.Documents.SpecialProject.${projectType}`);
        }

        return projectName;
    }

    private getDesignExternalMetadata(metadata: { [key: string]: string }) {
        const designExternalMetadata = new DesignExternalMetaData();

        for (const key in metadata) {
            const value = metadata[key];

            switch (key) {
                case 'region':
                    if (value != null && value != '') {
                        designExternalMetadata.region = parseInt(value, 10);
                    }

                    break;
            }
        }

        return designExternalMetadata;
    }

    /**
     * Creates promise to update design with new data.
     * @param data - The request data.
     */
    private putSmallDesignChangePromise(data: IDefferedData) {
        // make new meta-data entity
        const internalDesign = this.findDesignById(data.designId);

        return this.browser.toBase64(data.contentOverride)
            .then((designAsBase64Endoded) => {
                return this.putSmallDesignChange(data.designId, data.projectId, data.designName, designAsBase64Endoded, this.getSessionKeyForDesign(data.designId), data.metadataOverride, data.unlock)
                    .then(() => {
                        if (data.metadataOverride != null) {
                            // update meta-data in proxy store
                            internalDesign.metaData.name = data.metadataOverride.name;
                            internalDesign.metaData.region = data.metadataOverride.region;
                        }

                        internalDesign.designName = data.designName;

                        const now: Date = new Date();
                        const nowUtc: Date = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds()));
                        internalDesign.changeDate = nowUtc;

                        this.updateProject(data.designId, data.projectId);
                    });
            })
            .catch(() => {
                return this.$q.reject();
            })
            .finally(() => {
                this.defferedRequests[data.designId].running = null;

                // start new request for deferred and save reference into runningDefferd to resolve it later
                // clear deferred so new requests can be added
                if (this.defferedRequests[data.designId].deffered != null) {
                    this.defferedRequests[data.designId].runningDeffered = this.defferedRequests[data.designId].deffered;

                    this.defferedRequests[data.designId].running = this.putSmallDesignChangePromise(this.defferedRequests[data.designId].defferedData)
                        .then(() => this.defferedRequests[data.designId].runningDeffered.resolve())
                        .catch(() => this.defferedRequests[data.designId].runningDeffered.reject());

                    this.defferedRequests[data.designId].deffered = null;
                }
            });
    }

    private deleteProjectFunc(projId: string) {
        delete this.internalRawProject.Projects[projId];
        const deleteDocs = Object.keys(this.internalRawProject.Documents).filter((it) => this.internalRawProject.Documents[it].ProjectId == projId);
        deleteDocs.forEach((it) => this.deleteDesignFunc(it));

        // remove from project flat list
        delete this.projectsFlat[projId];

        // remove form hierarchical list - in line recursion
        delete this._projects[projId];

        const remove = function (x: Project) {
            delete x.subProjects[projId];

            for (const s in x.subProjects) remove(x.subProjects[s]);

        };
        for (const s in this._projects) remove(this._projects[s]);
    }

    private deleteDesignFunc(designId: string) {
        const project = this.findProjectByDesignId(designId);

        if (project == null) {
            throw new Error('Invalid project ID.');
        }

        const deleteDoc = Object.keys(this.internalRawProject.Documents).find((it) => this.internalRawProject.Documents[it].Id == designId);
        delete this.internalRawProject.Documents[deleteDoc];

        // remove from project
        delete project.directChildDesigns[designId];
        delete project.designs[designId];

        // remove from all parent projects
        let parentX: Project = this.findProjectById(project.parentId);
        while (parentX != undefined && parentX != null) {
            delete parentX.designs[designId];
            parentX = this.findProjectById(parentX.parentId);
        }

        // remove from flat design list
        delete this._designsFlat[designId];
    }

    private updateProject(designId: string, projectId: string) {
        const design = this.findDesignById(designId);
        if (design == null) {
            throw new Error('Invalid design id.');
        }

        const project = this.findProjectById(projectId);
        if (project == null) {
            throw new Error('Invalid project id.');
        }

        design.projectId = projectId;
        design.projectName = project.name;

        // remove design from project
        for (const pKey in this.projectsFlat) {
            const p = this.projectsFlat[pKey];
            delete p.directChildDesigns[design.id];
            delete p.designs[design.id];
        }

        // remove design from designs
        delete this._designsFlat[design.id];

        // add design to project
        if (project.directChildDesigns == null) {
            project.directChildDesigns = {};
        }

        project.directChildDesigns[design.id] = design;
        project.designs[design.id] = design;

        // add design to all parent designs
        let parentX: Project = this.findProjectById(project.parentId);
        while (parentX != null) {
            parentX.designs[design.id] = design;
            parentX = this.findProjectById(parentX.parentId);
        }

        // add new design to designs in general
        this._designsFlat[design.id] = design;
    }

    // make any special stuff on special folders - for now only draft folder
    private setSpecialProjectProperties(project: Project): void {
        if (project.name.indexOf('#$') == 0) {
            const projectType = project.name.substr(2);
            switch (projectType) {
                case 'draft':
                    project.projectType = ProjectType.draft;
                    break;
            }
            project.name = this.localization.getLocalizedString(`Agito.Hilti.Purchaser.Documents.SpecialProject.${projectType}`);

        }
    }

    /**
     * Parse raw data from document service to IDesignListItem.
     * @param data
     */
    private toIDesignListItem(data: IGetDocumentResponse): IDesignListItem {
        const ret: IDesignListItem = {
            id: data.documentid,
            projectId: data.project.projectid,
            projectName: data.project.name,
            changeDate: data.lastchange != null ? new Date(data.lastchange * 1000) : null,
            createDate: data.created != null ? new Date(data.created * 1000) : null,
            designName: data.name,
            locked: data.locked,
            lockedUser: data.lockedby,
            metaData: this.fromServiceMetaData(data.metadata, data.documentid, data.name)
        };
        return ret;
    }

    /**
     * converts the meta data entity to a form appropriate for sending to the document service.
     * @param metaData
     */
    private toServiceMetaData(metaData: DesignExternalMetaData): any[] {
        if (metaData == null) {
            return null;
        }

        const ret: any[] = [];

        if (metaData != undefined && metaData != null) {
            if (metaData.region != undefined && metaData.region != null) {
                ret.push({ key: 'region', value: metaData.region.toString() });
            }
        }

        return ret;
    }

    /**
     * Parses some of the results of the read meta-data methods to the specified entity.
     * @param serviceMetaData
     * @param designId
     * @param designName
     */
    private fromServiceMetaData(serviceMetaData: { key: string, value: string }[], designId: string, designName: string): DesignExternalMetaData {
        const ret: DesignExternalMetaData = new DesignExternalMetaData();

        for (const rec of serviceMetaData) {
            switch (rec.key) {
                case 'region':
                    if (rec.value != undefined && rec.value != null && rec.value != '') {
                        ret.region = parseInt(rec.value);
                    }
                    break;
            }
        }

        return ret;
    }

    /**
     * Returns users on the project
     * @param projectId
     */
    private getUsersOnProjectByIdInternal(projectId: string): ng.IPromise<ProjectUser[]> {
        const url = `${this.documentServicePublicUrlBase}User?projectid=${projectId}`;

        return this.$http.get<any>(url, this.getHttpConfig())
            .then((response) => {
                return response.data;
            })
            .catch<any>((response) => {
                this.logger.logServiceError(response, 'document-service', 'get users on project by id');
                if (!this.handleError(response, { projectId }, null)) {
                    const param: IApplicationError = {
                        response,
                        endPointUrl: url
                    };
                    if (response.status == 404) {
                        this.onServiceErrorHandler.showProjectArchivedModal(param);
                    }
                    else if (response.status != 401) {

                        this.modal.alert.openServiceError(param);
                    }
                    return this.$q.reject(response);
                }
                return this.$q.when();
            });
    }

    /**
     * Adds user to the project
     * @param data
     */
    private addUsersOnProjectByIdInternal(data: ProjectUser): ng.IPromise<any> {
        const url = `${this.documentServicePublicUrlBase}User`;

        const sendData = {
            projectid: data.projectid,
            user: data.user
        };

        return this.$http.post(url, sendData, this.getHttpConfig())
            .then(response => {
                return response;
            })
            .catch<any>((response) => {
                this.logger.logServiceError(response, 'document-service', 'add user to the project by id');
                if (!this.handleError(response, { data }, null)) {
                    const applicationError: IApplicationError = {
                        response,
                        endPointUrl: url,
                        requestPayload: sendData
                    };
                    if (response.status == 404) {
                        const param: IAlertParameters = {
                            title: this.localization.getLocalizedString('Agito.Hilti.Purchaser.ShareProject.UserNotAdded.Title'),
                            message: this.localization.getLocalizedString('Agito.Hilti.Purchaser.ShareProject.UserNotAdded.Message'),
                            applicationError
                        };
                        this.modal.alert.open(param);
                    }
                    else {

                        this.modal.alert.openServiceError(applicationError);
                    }
                    return this.$q.reject(response);
                }
                return this.$q.when();
            });
    }

    /**
     * Removes user from the project
     * @param data
     */
    private removeUsersOnProjectByIdInternal(data: ProjectUser): ng.IPromise<any> {
        const url = `${this.documentServicePublicUrlBase}User`;

        const sendData = {
            projectid: data.projectid,
            user: data.user
        };

        const httpConfig = this.getHttpConfig();
        httpConfig.headers = {};
        httpConfig.data = sendData;
        httpConfig.headers['Content-Type'] = 'application/json';

        return this.$http.delete(url, httpConfig)
            .then(response => {
                return response;
            })
            .catch<any>((response) => {
                this.logger.logServiceError(response, 'document-service', 'delete user from the project by id');
                if (!this.handleError(response, { data }, null)) {
                    if (response.status != 401) {
                        const param: IApplicationError = {
                            response,
                            endPointUrl: url,
                            requestPayload: sendData
                        };
                        this.modal.alert.openServiceError(param);
                    }
                    return this.$q.reject(response);
                }
                return this.$q.when();
            });
    }

    private getHttpConfig() {
        if (!this.user.isAuthenticated) {
            throw new Error('Unauthenticated');
        }

        return {
            ignoreErrors: true,
        } as IRequestConfig;
    }

    private addNewProject(project: Project, projectLevelType: ModalDialogType): ng.IPromise<any> {
        const url = `${this.documentServicePublicUrlBase}project`;

        const rData = { name: project.name, parentprojectid: (project.parentId == null) ? null : project.parentId, isCompanyProject: project.isCompanyProject };
        this.logServiceRequest('addNewProject', rData, url);

        return this.$http.post(url, rData, this.getHttpConfig())
            .then((response) => {
                this.logServiceResponse('addNewProject', response);
                project.id = (response.data as any).projectid as string;
                this.setSpecialProjectProperties(project);
            })
            .catch<any>((response) => {
                this.logger.logServiceError(response, 'document-service', 'post project');
                if (!this.handleError(response, null, null)) {
                    const param: IApplicationError = {
                        response,
                        endPointUrl: url,
                        requestPayload: rData
                    };
                    if (response.status == 409) {
                        this.onServiceErrorHandler.showExistingProjectNameModal(projectLevelType, param);
                    }
                    else if (response.status == 404) {
                        this.onServiceErrorHandler.showProjectArchivedModal(param);
                    }
                    else if (response.status != 401) {
                        this.modal.alert.openServiceError(param);
                    }
                    return this.$q.reject(response);
                }
                return this.$q.when();
            });
    }

    private updateExistingProject(project: Project, projectLevelType: ModalDialogType): ng.IPromise<any> {
        const url = `${this.documentServicePublicUrlBase}project/`;

        const rData = { projectid: project.id, name: project.name, parentprojectid: project.parentId };
        this.logServiceRequest('updateExistingProject', rData, url);

        return this.$http.put(url, rData, this.getHttpConfig())
            .then(response => {
                this.logServiceResponse('updateExistingProject', response);
            })
            .catch<any>((response) => {
                this.logger.logServiceError(response, 'document-service', 'put project');
                if (!this.handleError(response, null, null)) {
                    const param: IApplicationError = {
                        response,
                        endPointUrl: url,
                        requestPayload: rData
                    };
                    if (response.status == 409) { // conflict; duplicate name
                        this.onServiceErrorHandler.showExistingProjectNameModal(projectLevelType, param);
                    }
                    else if (response.status == 404) {
                        this.onServiceErrorHandler.showProjectArchivedModal(param);
                    }
                    else if (response.status != 401) {
                        this.modal.alert.openServiceError(param);
                    }
                    return this.$q.reject(response);
                }
                return this.$q.when();
            });
    }

    private archiveExistingProject(projectId: string): ng.IPromise<any> {
        const url = `${this.documentServicePublicUrlBase}project/${projectId}`;

        this.logServiceRequest('archiveExistingProject', url);

        return this.$http.delete(url, this.getHttpConfig())
            .then(response => {
                this.logServiceResponse('archiveExistingProject', response);
                return this.$q.when(response.data);
            })
            .catch<any>((response) => {
                this.logger.logServiceError(response, 'document-service', 'delete project');
                if (response.status == 423) {
                    return this.$q.reject(new CantArchiveProjectsBecauseDocumentInUse());
                }
                if (!this.handleError(response, null, null)) {
                    const param: IApplicationError = {
                        response,
                        endPointUrl: url
                    };
                    if (response.status == 404) {
                        this.onServiceErrorHandler.showProjectArchivedModal(param);
                    }
                    else if (response.status != 401) {
                        this.modal.alert.openServiceError(param);
                    }
                    return this.$q.reject(response);
                }
                return this.$q.when();
            });
    }

    private restoreExistingProject(projectId: string, projectLevelType: ModalDialogType): ng.IPromise<DocumentGetResponse> {
        const url = `${this.documentServicePublicUrlBase}project/dearchive/${projectId}`;

        this.logServiceRequest('restoreExistingProject', url);

        return this.$http.post(url, {}, this.getHttpConfig())
            .then(response => {
                this.logServiceResponse('restoreExistingProject', response);
                const data = response.data as any;
                const result = {
                    Projects: Object.fromEntries(Object.entries(data.projects).map(([id, it]: [string, any]) =>
                        [id, {
                            DateChanged: it.dateChanged,
                            DateCreated: it.dateCreated,
                            Id: it.id,
                            Name: this.projectNameExists(it.name, it.parentId, it.id) ? this.createUniqueName(it.name, Object.values(this._projectsFlat).map(project => project.name)) : it.name,
                            ParentId: it.parentId,
                            Owner: it.owner,
                            IsCompanyProject: it.isCompanyProject,
                            ReadOnly: it.readOnly
                        } as DocumentGetProject])),
                    Documents: Object.fromEntries(Object.entries(data.documents).map(([id, it]: [string, any]) =>
                        [id, {
                            DateChanged: it.dateChanged,
                            DateCreated: it.dateCreated,
                            Id: it.id,
                            Locked: it.locked,
                            Metadata: it.metadata,
                            ProjectId: it.projectId,
                            LockedUserName: it.lockedUserName,
                            Name: it.name,
                            Type: it.type
                        } as DocumentGetDocument]))
                } as DocumentGetResponse;
                return this.$q.when(result);
            })
            .catch<any>((response) => {
                this.logger.logServiceError(response, 'document-service', 'restore project');
                if (response.status == 423) {
                    return this.$q.reject(new CantRestoreProjectsBecauseDocumentInUse());
                }
                if (!this.handleError(response, null, null)) {
                    const param: IApplicationError = {
                        response,
                        endPointUrl: url
                    };
                    if (response.status == 404) {
                        this.onServiceErrorHandler.showProjectArchivedModal(param);
                    }
                    else if (response.status == 409) {
                        this.onServiceErrorHandler.showExistingProjectNameModal(projectLevelType, param);
                    }
                    else if (response.status != 401) {
                        this.modal.alert.openServiceError(param);
                    }
                    return this.$q.reject(response);
                }
                return this.$q.when();
            });
    }

    private removeExistingArchivedProject(projectId: string): ng.IPromise<any> {
        const url = `${this.documentServicePublicUrlBase}project/permanentArchived/${projectId}`;

        this.logServiceRequest('removeExistingArchivedProject', url);

        return this.$http.delete(url, this.getHttpConfig())
            .then(response => {
                this.logServiceResponse('removeExistingArchivedProject', response);
            })
            .catch<any>((response) => {
                this.logger.logServiceError(response, 'document-service', 'delete archived project');
                if (response.status == 423) {
                    return this.$q.reject(new CantDeleteProjectsBecauseDocumentInUse());
                }
                if (!this.handleError(response, null, null)) {
                    const param: IApplicationError = {
                        response,
                        endPointUrl: url
                    };
                    if (response.status == 404) {
                        this.onServiceErrorHandler.showProjectArchivedModal(param);
                    }
                    else if (response.status != 401) {
                        this.modal.alert.openServiceError(param);
                    }
                    return this.$q.reject(response);
                }
                return this.$q.when();
            });
    }

    private removeExistingProject(projectId: string): ng.IPromise<any> {
        const url = `${this.documentServicePublicUrlBase}project/permanent/${projectId}`;

        this.logServiceRequest('removeExistingProject', url);

        return this.$http.delete(url, this.getHttpConfig())
            .then(response => {
                this.logServiceResponse('removeExistingProject', response);
            })
            .catch<any>((response) => {
                this.logger.logServiceError(response, 'document-service', 'delete project');
                if (response.status == 423) {
                    return this.$q.reject(new CantDeleteProjectsBecauseDocumentInUse());
                }
                if (!this.handleError(response, null, null)) {
                    const param: IApplicationError = {
                        response,
                        endPointUrl: url
                    };
                    if (response.status == 404) {
                        this.onServiceErrorHandler.showProjectArchivedModal(param);
                    }
                    else if (response.status != 401) {
                        this.modal.alert.openServiceError(param);
                    }
                    return this.$q.reject(response);
                }
                return this.$q.when();
            });
    }

    private convertExistingProject(projectId: string): ng.IPromise<any> {

        const url = `${this.documentServicePublicUrlBase}project/convertProject/${projectId}`;

        this.logServiceRequest('convertExistingProject', url);

        return this.$http.get(url, this.getHttpConfig())
            .then(response => {
                this.logServiceResponse('convertExistingProject', response);
            })
            .catch<any>((response) => {
                if (!this.handleError(response, null, null)) {
                    const param: IApplicationError = {
                        response,
                        endPointUrl: url
                    };
                    if (response.status != 401) {
                        this.modal.alert.openServiceError(param);
                    }
                    return this.$q.reject(response);
                }
                return this.$q.when();
            });
    }

    private getDocumentsByProjectId(projectId: string): ng.IPromise<any> {
        const url = `${this.documentServicePublicUrlBase}document/get`;
        this.logServiceRequest('getDocumentsByProjectId', url);

        return this.$http.get(url, this.getHttpConfig())
            .then(response => {
                this.logServiceResponse('getDocumentsByProjectId', response);
                return response.data;
            })
            .catch<any>((response) => {
                this.logger.logServiceError(response, 'document-service', 'get project documents');
                if (!this.handleError(response, null, null)) {
                    const param: IApplicationError = {
                        response,
                        endPointUrl: url
                    };
                    if (response.status != 401) {
                        this.modal.alert.openServiceError(param);
                    }
                    return this.$q.reject(response);
                }
                return this.$q.when();
            });
    }

    private findDesignByNameInternal(designName: string, projectId: string, forceFromServer: boolean): ng.IPromise<IDesignListItem> {
        let project = this.findProjectById(projectId);

        if (project != null) {

            if (forceFromServer === true) {
                return this.getDocumentsByProjectId(projectId).then((data: any) => {
                    const result = {
                        Projects: Object.fromEntries(Object.entries(data.Projects).map(([id, it]: [string, any]) =>
                            [id, {
                                DateChanged: it.dateChanged,
                                DateCreated: it.dateCreated,
                                Id: it.id,
                                Name: it.name,
                                ParentId: it.parentId,
                                Owner: it.owner,
                                IsCompanyProject: it.isCompanyProject,
                                ReadOnly: it.readOnly
                            } as DocumentGetProject])),
                        Documents: Object.fromEntries(Object.entries(data.Documents).map(([id, it]: [string, any]) =>
                            [id, {
                                DateChanged: it.DateChanged,
                                DateCreated: it.dateCreated,
                                Id: it.id,
                                Locked: it.locked,
                                Metadata: it.metadata,
                                ProjectId: it.projectId,
                                LockedUserName: it.lockedUserName,
                                Name: it.name,
                                Type: it.type
                            } as DocumentGetDocument]))
                    } as DocumentGetResponse;

                    this.getInternal(result);
                    project = this.findProjectById(projectId);

                    const foundDesign = Object.values(project.directChildDesigns).find((design) => design.designName.toLowerCase().trim() == designName.toLowerCase().trim());

                    if (foundDesign != null) {
                        return foundDesign;
                    }

                    return null;
                });
            }
            else {
                return this.$q.when().then(() => {
                    const foundDesign = Object.values(project.directChildDesigns).find((design) => design.designName.toLowerCase().trim() == designName.toLowerCase().trim());

                    if (foundDesign != null) {
                        return foundDesign;
                    }

                    return null;
                });
            }

        }

        return this.$q.when(null);
    }

    /**
     * Reads all the locked designs of a project.
     * @param projectId
     */
    private readLockedDesignsOfProject(projectId: string): ng.IPromise<CantPerformActionReasonProblematicDocumentInformation[]> {
        interface IGetProjectResponse {
            documents: IGetProjectResponseDocument[];
        }

        interface IGetProjectResponseDocument {
            locked: boolean;
            documentid: string;
            name: string;
            lockedby: string;
        }

        const url: string = `${this.documentServicePublicUrlBase}project/${projectId}`;

        this.logServiceRequest('readDesignsOfProject', url);
        return this.$http.get<IGetProjectResponse>(url, this.getHttpConfig())
            .then<CantPerformActionReasonProblematicDocumentInformation[]>((response) => {
                this.logServiceResponse('readDesignsOfProject', response);
                const designs: CantPerformActionReasonProblematicDocumentInformation[] = [];
                if (response.data.documents instanceof Array) {
                    for (const pRaw of response.data.documents) {
                        if (pRaw.locked != undefined && pRaw.locked != null && pRaw.locked == true) {
                            const p: CantPerformActionReasonProblematicDocumentInformation = new CantPerformActionReasonProblematicDocumentInformation();
                            p.designId = pRaw.documentid;
                            p.document = pRaw.name;
                            p.username = pRaw.lockedby;
                            designs.push(p);
                        }
                    }
                }
                return designs;
            })
            .catch<any>((response) => {
                this.logger.logServiceError(response, 'document-service', 'documents');
                if (!this.handleError(response, null, null)) {
                    const param: IApplicationError = {
                        response,
                        endPointUrl: url
                    };
                    if (response.status != 401) {
                        this.modal.alert.openServiceError(param);
                    }
                    return this.$q.reject(response);
                }
                return this.$q.when();
            });
    }

    private userHasAccessToProjectInternal(projectId: string): ng.IPromise<any> {
        const url = `${this.documentServicePublicUrlBase}User/isuseronproject/${projectId}`;

        return this.$http.get(url, this.getHttpConfig())
            .then((response) => {
                return response.data;
            })
            .catch((response) => {
                if (!this.handleError(response, { projectId }, null)) {
                    const param: IApplicationError = {
                        response,
                        endPointUrl: url
                    };
                    if (response.status != 401) {
                        this.modal.alert.openServiceError(param);
                    }
                    return this.$q.reject(response);
                }
                return this.$q.when();
            });
    }

    private getDesignBasicDocumentInformation(id: string): ng.IPromise<IDesignListItem> {
        const url = `${this.documentServicePublicUrlBase}document/${id}`;

        this.logServiceRequest('getDesignBasicDocumentInformation', url);

        return this.$http.get<IGetDocumentResponse>(url, this.getHttpConfig())
            .then<IDesignListItem>((response) => {
                this.logServiceResponse('getDesignBasicDocumentInformation', response);
                return this.toIDesignListItem(response.data);
            })
            .catch<IDesignListItem>((response) => {
                this.logger.logServiceError(response, 'document-service', 'read document');
                if (!this.handleError(response, null, null)) {
                    const param: IApplicationError = {
                        response,
                        endPointUrl: url
                    };
                    if (response.status != 401) {
                        this.modal.alert.openServiceError(param);
                    }
                    return this.$q.reject(response);
                }
                return this.$q.when(null);
            });
    }

    /**
     * Add new empty file and return document id when done.
     * @param project
     * @param designName
     * @param fileContent
     * @param metaData
     */
    private addNewFile(project: Project, designName: string, fileContent: string, metaData: any[], canGenerateUniqueName: boolean, ignoreConflict: boolean): ng.IPromise<IDocumentServiceDesign> {
        const url = `${this.documentServicePublicUrlBase}document`;

        const parentProject = project.parentId != null ? this.findProjectById(project.parentId) : project;

        const rData = { projectid: project.id, name: designName, filecontent: fileContent, type: 1, metadata: metaData, adjustdocumentname: canGenerateUniqueName, documentlocked: false };
        this.logServiceRequest('addNewFile', rData, url);

        return this.$http.post<any>(url, rData, this.getHttpConfig())
            .then<IDocumentServiceDesign>(response => {
                this.tracking.trackOnDesignFileImported();
                this.logServiceResponse('addNewFile', response);
                this.setNewSessionForNewlyCreatedDesign(response.data.documentid, response.data.sessionid);
                return response.data as IDocumentServiceDesign;
            })
            .catch<any>((response) => {
                this.logger.logServiceError(response, 'document-service', 'add new document');
                if (!this.handleError(response, null, null)) {
                    const param: IApplicationError = {
                        response,
                        endPointUrl: url,
                        requestPayload: rData
                    };
                    if (response.status == 409 && response.data.content.conflictType == 1) {
                        this.modal.showMaxDesignsLimitModal();
                    }
                    else if (response.status == 409 && response.data.content.conflictType == 0) {
                        if (ignoreConflict !== true) {
                            this.onServiceErrorHandler.showExistingDesignNameModal(param);
                        }
                    }
                    else if (response.status == 404) {
                        this.onServiceErrorHandler.showProjectArchivedModal(param);
                    }
                    else if (response.status != 401) {
                        this.modal.alert.openServiceError(param);
                    }
                    return this.$q.reject(response);
                }
                return this.$q.when();
            });
    }

    /**
     * Gets the content of the designs and lock it for exclusive use.
     * @param designIds
     */
    private getDesignContentExclusives(designIds: string[]): ng.IPromise<IDocumentServiceResponse[] | any> {
        const url = `${this.documentServicePublicUrlBase}documentContent/GetExclusives`;

        this.logServiceRequest('getDesignContentExclusives', designIds, url);
        const requestCofig = this.getHttpConfig();

        return this.$http.post<any>(url, designIds, requestCofig)
            .then((response) => {
                const projectDesigns: IDocumentServiceResponse[] = [];

                for (let i = 0; i < response.data.length; i++) {
                    // content is directly returned
                    const projectDesignAsJsonString = this.browser.fromBase64(response.data[i].filecontent);

                    projectDesigns.push({
                        documentId: response.data[i].documentid,
                        filecontent: JSON.parse(projectDesignAsJsonString),
                        documentName: response.data[i].documentname
                    });
                }

                return projectDesigns;
            })
            .catch<IDocumentServiceResponse[]>((response) => {
                this.logger.logServiceError(response, 'document-service', 'get design content');

                if (!this.handleError(response, {}, null)) {
                    const param: IApplicationError = {
                        response,
                        endPointUrl: url,
                        requestPayload: designIds
                    };
                    if (response.status == 404) {
                        this.onServiceErrorHandler.showProjectContentChangedModal(param);
                    }
                    else if (response.status != 401) {
                        this.modal.alert.openServiceError(param);
                    }

                    return this.$q.reject(response);
                }
                return this.$q.when<IDocumentServiceResponse[]>(undefined);
            });
    }

    /**
     * Make a small change to the already exclusively locked and open design, without closing or releasing the content.
     * @param id - design id
     * @param base64XmlContent - the design content in base64 encoded XML format
     * @param sessionId - id of the design change session
     * @param metaData
     */
    private putSmallDesignChange(designId: string, projectId: string, designName: string, base64XmlContent: string, sessionId: string, metaData: DesignExternalMetaData, unlock = false): ng.IPromise<void> {
        const url = `${this.documentServicePublicUrlBase}documentcontent`;

        const rData = {
            unlock,
            key: sessionId,
            documentid: designId,
            projectid: projectId,
            name: designName,
            filecontent: base64XmlContent,
            metadata: this.toServiceMetaData(metaData)
        };
        this.logServiceRequest('putSmallDesignChange', rData, url);

        const config = this.getHttpConfig();
        config.responseType = 'json';

        return this.$http.put(url, rData, config)
            .then(reponse => {
                this.logServiceResponse('putSmallDesignChange', reponse);
            })
            .catch<any>((response) => {
                this.logger.logServiceError(response, 'document-service', 'small design update failed');
                if (!this.handleSessionExpiredError(response, { designId }, [428]) && !this.handleError(response, { designId }, [428])) {
                    const param: IApplicationError = {
                        response,
                        endPointUrl: url,
                        requestPayload: rData
                    };
                    if (response.status == 409) {
                        this.onServiceErrorHandler.showExistingDesignNameModal(param);
                    }
                    else if (response.status != 401) {
                        this.modal.alert.openServiceError(param);
                    }
                }
                return this.$q.reject();
            });
    }

    /**
     * Update design print screen image when a design report is ready for export
     * @param id - design id
     * @param base64XmlContent - the design image content in base64 encoded XML format
     * @param sessionId - id of the design change session
     */
    private uploadDesignImageInternal(designId: string, base64XmlContent: string, sessionId: string): ng.IPromise<void> {
        const url = `${this.documentServicePublicUrlBase}documentcontent/UploadDocumentImage`;

        const rData = {
            key: sessionId,
            documentid: designId,
            modelimage: base64XmlContent
        };
        this.logServiceRequest('uploadDocumentImage', rData, url);

        const config = this.getHttpConfig();
        config.responseType = 'json';

        return this.$http.post(url, rData, config)
            .then(reponse => {
                this.logServiceResponse('uploadDocumentImage', reponse);
            })
            .catch<any>((response) => {
                this.logger.logServiceError(response, 'document-service', 'upload design image failed');
                if (!this.handleSessionExpiredError(response, { designId }, [428]) && !this.handleError(response, { designId }, [428])) {
                    const param: IApplicationError = {
                        response,
                        endPointUrl: url,
                        requestPayload: rData
                    };
                    if (response.status != 401) {
                        this.modal.alert.openServiceError(param);
                    }
                }
                return this.$q.when();
            });
    }

    /**
     * Unlock the document on the document service.
     * @param id - design document id.
     */
    private postUnlockDocument(id: string): ng.IPromise<void> {
        const sessionKey = this.getSessionKeyForDesign(id);
        const url = `${this.documentServicePublicUrlBase}documentcontent/${id}/false/${sessionKey}`;
        this.logServiceRequest('postUnlockDocument', url);

        const httpConfig = this.getHttpConfig();
        httpConfig.responseType = 'json';

        return this.$http.post(url, null, httpConfig)
            .then(response => {
                this.logServiceResponse('postUnlockDocument', response);
            })
            .catch<any>((response) => {
                this.logger.logServiceError(response, 'document-service', 'design publish failed');
                if (!this.handleError(response, { designId: id }, null)) {
                    const param: IApplicationError = {
                        response,
                        endPointUrl: url
                    };
                    if (response.status != 401) {
                        this.modal.alert.openServiceError(param);
                    }
                    return this.$q.reject(response);
                }
                return this.$q.when();
            });
    }

    /**
     * Method returns a Design JSON object instance. The design instance is read from the XML file available on an URL (Amazon).
     * @param filePath - full path to the file on amazon.
     */
    private getJsonInstanceFromRemoteDesignFileUrl(filePath: string): ng.IPromise<Object> {
        const url = `${environment.baseUrl}Home/LoadAndReadDesign`;
        const rData = { designPath: filePath };
        this.logServiceRequest('getJsonInstanceFromRemoteDesignFileUrl', rData, url);

        return this.$http.post<any>(url, rData)
            .then((response) => {
                this.logServiceResponse('getJsonInstanceFromRemoteDesignFileUrl', response);

                try {
                    return JSON.parse(response.data.Content);
                } catch (error) {
                    return this.$q.reject('Invalid design on document service.');
                }
            })
            .catch<string>((response) => {
                this.logger.logServiceError(response, 'document-service', 'get design content call');
                return this.$q.reject(response);
            });
    }

    private archiveExistingDesign(designId: string): ng.IPromise<any> {
        const url = `${this.documentServicePublicUrlBase}document/${designId}`;

        this.logServiceRequest('archiveExistingDesign', url);

        return this.$http.delete(url, this.getHttpConfig())
            .then(response => {
                this.logServiceResponse('archiveExistingDesign', response);
                return this.$q.when(response.data);
            })
            .catch<any>((response) => {
                this.logger.logServiceError(response, 'document-service', 'archive design');
                if (response.status == 423) {
                    return this.$q.reject(new CantArchiveDesignBecauseLockedByOtherUser());
                }
                if (!this.handleError(response, null, null)) {
                    const param: IApplicationError = {
                        response,
                        endPointUrl: url
                    };
                    if (response.status != 401) {
                        this.modal.alert.openServiceError(param);
                    }
                    return this.$q.reject(response);
                }
                return this.$q.when();
            });
    }

    private removeExistingDesigns(designIds: string[]): ng.IPromise<any> {
        let url = `${this.documentServicePublicUrlBase}document/permanentdelete`;
        for (let i = 0; i < designIds.length; i++) {
            url += `${i === 0 ? '?' : '&'}documentIds=${designIds[i]}`;
        }

        this.logServiceRequest('removeExistingDesigns', url);

        return this.$http.delete(url, this.getHttpConfig())
            .then(response => {
                this.logServiceResponse('removeExistingDesigns', response);
                return this.$q.when(response.data);
            })
            .catch<any>((response) => {
                this.logger.logServiceError(response, 'document-service', 'remove designs');
                if (!this.handleError(response, null, null)) {
                    const param: IApplicationError = {
                        response,
                        endPointUrl: url
                    };
                    if (response.status != 401) {
                        this.modal.alert.openServiceError(param);
                    }
                    return this.$q.reject(response);
                }
                return this.$q.when();
            });
    }

    private setNewSessionForNewlyCreatedDesign(design: string, session: string) {
        this._designSessionMapping.push({ designId: design, sessionId: session, isCancelled: false });
    }

    private openNewSessionForDesign(designId: string): string {
        // this is not necessary because there are cases where it is not certain if session is already open or not
        // if (_.any(this._designSessionMapping, m => m.designId == designId))
        //    throw new Error("Failed to create new session because a session for the specified design is already open.");

        // just return new design if already exists, where we have certain methods where it is not certain if session is already open
        if (this._designSessionMapping.some(m => m.designId == designId)) {
            return this.getSessionKeyForDesign(designId);
        }

        // create new session
        const key = this.guid.new();

        this._designSessionMapping.push({ designId, sessionId: key, isCancelled: false });

        return key;
    }

    private getSessionKeyForDesign(designId: string): string {
        const mappig = this._designSessionMapping.find(x => x.designId == designId);
        if (mappig == null) {
            throw new Error('Session is not opened for the specified design.');
        }

        return mappig.sessionId;
    }

    private isCanceledSessionForDesign(designId: string): boolean {
        const mappig = this._designSessionMapping.find(x => x.designId == designId);
        if (mappig == null) {
            return true;
        }

        return mappig.isCancelled;
    }

    private closeSessionForDesign(designId: string): void {
        const mappig = this._designSessionMapping.find(x => x.designId == designId);
        if (mappig == null) {
            throw new Error('Session is not opened for the specified design.');
        }

        this._designSessionMapping = this._designSessionMapping.filter(x => x.designId != designId);
    }

    private cancelSessionForDesign(designId: string): void {
        const mappig = this._designSessionMapping.find(x => x.designId == designId);
        if (mappig == null) {
            return;
        }

        mappig.isCancelled = true;
    }

    /**
     * the session in which the document was being edited and changed was overtaken.
     * @param response - the entire server error response.
     * @param additionalData - any additional data
     * @param errorsToHandle - what error code should this call handle.
     */
    private handleSessionExpiredError(response: any, additionalData: any, errorsToHandle: number[]) {
        let errorHandeled = false;

        if (errorsToHandle != undefined && errorsToHandle != null) {
            if (response.status == 428 && errorsToHandle.some((x) => x == 428)) {
                errorHandeled = true;
                this.modal.confirmChange.open(
                    'traSaveLock',
                    this.localization.getLocalizedString(`Agito.Hilti.Purchaser.DocumentService.Alerts.SessionExpired.Title`),
                    this.localization.getLocalizedString(`Agito.Hilti.Purchaser.DocumentService.Alerts.SessionExpired.Description`),
                    this.localization.getLocalizedString(`Agito.Hilti.Purchaser.DocumentService.Alerts.SessionExpired.ConfirmButton`),
                    this.localization.getLocalizedString(`Agito.Hilti.Purchaser.DocumentService.Alerts.SessionExpired.CancelButton`),
                    () => {
                        if (additionalData != undefined && additionalData != null && additionalData.designId != undefined && additionalData.designId != null) {
                            this.cancelSessionForDesign(additionalData.designId);
                        }
                        this.$location.path(urlPath.projectAndDesign);
                        this.modal.confirmChange.close();
                    },
                    () => { this.modal.confirmChange.close(); }
                );
            }
        }

        return errorHandeled;
    }

    /**
     * Handle error codes that demand the same reaction no matter in what circumstances (what was called) it occurred.
     * @param response - the entire server error response.
     * @param additionalData - any additional data
     * @param errorsToHandle - what error code should this call handle.
     */
    private handleError(response: any, additionalData: any, errorsToHandle: number[]) {
        const errorHandeled = false;
        if (errorsToHandle != undefined && errorsToHandle != null) {

        }

        return errorHandeled;
    }

    private getDocumentMetaData(documentRaw: string) {
        const ret: any[] = [];

        const content = JSON.parse(this.browser.fromBase64(documentRaw));

        /*if (metaData.anchorName != undefined && metaData.anchorName != null && metaData.anchorName != '')
            ret.push({ key: 'anchorName', value: metaData.anchorName });*/

        if (content['Options'] != null && content['Options']['RegionId'] != null) {
            ret.push({ key: 'region', value: content['Options']['RegionId'].toString() });
        }

        if (content['Options'] != null && content['Options']['DesignStandard'] != null) {
            ret.push({ key: 'standard', value: content['Options']['DesignStandard'].toString() });
        }

        if (content['CalculatedData'] != null && content['CalculatedData']['DesignMethod'] != null) {
            ret.push({ key: 'designMethod', value: content['CalculatedData']['DesignMethod'].toString() });
        }

        if (content['ProjectDesignType'] != null) {
            ret.push({ key: 'designType', value: content['ProjectDesignType'].toString() });
        }

        /*if (metaData.approvalNumber != undefined && metaData.approvalNumber != null && metaData.approvalNumber != '')
            ret.push({ key: 'approvalNumber', value: metaData.approvalNumber });*/

        return ret;
    }
}
