import cloneDeep from 'lodash-es/cloneDeep';
import debounce from 'lodash-es/debounce';
import sortBy from 'lodash-es/sortBy';
import uniqBy from 'lodash-es/uniqBy';

import { IBaseBillOfMaterial } from '../../src/app/entities/bill-of-material';
import { Change } from '../../src/app/entities/change';
import { ProjectUser } from '../../src/app/entities/project-user';
import { TrackChanges } from '../../src/app/entities/track-changes';
import {
    ConvertAndCheckDesignRequest, ConvertAndCheckDesignRequestItem,
    ConvertAndCheckDesignResponseItem, ProjectDesingFileStorageFormat
} from '../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.PEDependencies';
import {
    CreatePurchaserProjectDataRequest, PurchaserDataEntity
} from '../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Data';
import {
    DesignEntity, DesignXMLEntity, ManualDesignEntity
} from '../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Data.Design';
import {
    UIPropertyConfig, UIPropertyValue
} from '../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Display';
import {
    PropertyUpdateResultEntity
} from '../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.UpdateData';
import { GuidService } from '../../src/app/guid.service';
import { PropertyMetaData, UIProperties } from '../../src/app/properties/properties';
import { DateTimeService } from '../../src/app/services/date-time.service';
import { ErrorHandlerService } from '../../src/app/services/error-handler.service';
import { LocalizationService } from '../../src/app/services/localization.service';
import { LoggerService, LogMessage } from '../../src/app/services/logger.service';
import { environment } from '../../src/environments/environment';
import { BrowserService } from '../Services/browser-service';
import { ChangesService } from '../../src/app/services/changes.service';
import { DataService } from '../Services/data-service';
import {
    DocumentService, IDesignListItem, IDocumentServiceResponse, ProjectType
} from '../Services/document-service';
import { IRequestConfig } from '../Services/http-interceptor-service';
import { ModalService } from '../Services/modal-service';
import { PromiseService } from '../Services/promise-service';
import { TrackingService } from '../Services/tracking-service';
import { UnitService } from '../Services/unit-service';
import { UserSettingsService } from '../Services/user-settings-service';
import { IBaseDesign } from './Design';
import { EventObject } from './EventObject';

const updateDebounce = 0;

export enum ProjectEvent {
    stateChanged
}

export interface IProjectConstructor {
    id?: string;
    parentId?: string;
    name?: string;
    designs?: { [id: string]: IDesignListItem; };
    directChildDesigns?: { [id: string]: IDesignListItem; };
    boms?: IBaseBillOfMaterial[];
    subProjects?: { [id: string]: Project; };
    projectType?: ProjectType;
    createDate?: Date;
    changeDate?: Date;
    owner?: boolean;
    isCompanyProject?: boolean;
    readonly?: boolean;
}

export interface IProjectDeps {
    changesService: ChangesService;
    localization: LocalizationService;
    $http: ng.IHttpService;
    logger: LoggerService;
    document: DocumentService;
    $q: ng.IQService;
    $rootScope: ng.IRootScopeService;
    guid: GuidService;
    promise: PromiseService;
    dateTime: DateTimeService;
    onServiceErrorHandler: ErrorHandlerService;
    modal: ModalService;
    browser: BrowserService;
    userSettings: UserSettingsService;
    dataService: DataService;
    tracking: TrackingService;
    unitService: UnitService;
}

export function getProjectDeps(
    changesService: ChangesService,
    localization: LocalizationService,
    $http: ng.IHttpService,
    logger: LoggerService,
    document: DocumentService,
    $q: ng.IQService,
    $rootScope: ng.IRootScopeService,
    guid: GuidService,
    promise: PromiseService,
    dateTime: DateTimeService,
    onServiceErrorHandler: ErrorHandlerService,
    modal: ModalService,
    browser: BrowserService,
    userSettings: UserSettingsService,
    dataService: DataService,
    tracking: TrackingService,
    unitService: UnitService
) {
    return {
        changesService,
        localization,
        $http,
        logger,
        document,
        $q,
        $rootScope,
        guid,
        promise,
        dateTime,
        onServiceErrorHandler,
        modal,
        browser,
        userSettings,
        dataService,
        tracking,
        unitService
    } as IProjectDeps;
}

export interface IProjectArchive {
    id: string;
    parentId: string;
    name: string;
    members: number;
    designs: number;
    created: Date;
    archived: Date;
}

export interface IConvertAndCheckDesignRequestItemExtended extends ConvertAndCheckDesignRequestItem {
    DesignName: string;
}

export interface IConvertAndCheckDesignResponseItemExtended extends ConvertAndCheckDesignResponseItem {
    DesignName: string;
}

export interface IConvertAndCheckDesignResponse {
    ConvertAndCheckDesignResponseItems: IConvertAndCheckDesignResponseItemExtended[];
    ElapsedMilliseconds: number;
}

interface IModelWatch {
    last: { [property: number]: Object };
}

export class Project extends EventObject<ProjectEvent> {

    public static readonly loadSize = 6;

    private static updateQueue: { [projectId: string]: ng.IDeferred<Project> };
    private static updateResults: { [projectId: string]: PropertyUpdateResultEntity };
    public id: string;
    public parentId: string;
    public name: string;
    public designs: { [id: string]: IDesignListItem; };
    public directChildDesigns: { [id: string]: IDesignListItem; };
    public boms: IBaseBillOfMaterial[];
    public subProjects: { [id: string]: Project; };
    public projectType: ProjectType;
    public createDate: Date;
    public changeDate: Date;
    public purchaserDataEntity: PurchaserDataEntity;
    public uniqueId: string;
    public scopeProjectId: string;
    public fullyLoaded: boolean;
    public loadFromStart: boolean;
    public owner: boolean;
    public isCompanyProject: boolean;
    public loading: boolean;
    public users: ProjectUser[];
    public readOnly: boolean;

    private modelChanges: TrackChanges;
    private localization: LocalizationService;
    private $http: ng.IHttpService;
    private logger: LoggerService;
    private changes: ChangesService;
    private document: DocumentService;
    private $q: ng.IQService;
    private $rootScope: ng.IRootScopeService;
    private guid: GuidService;
    private updateId: string;
    private updateDebounce: (updateId: string) => void;
    private updateDefer: ng.IDeferred<Project>;
    private updateHttpCancel: ng.IDeferred<void>;
    private lastModelChanges: Change[];
    private promise: PromiseService;
    private dateTime: DateTimeService;
    private modal: ModalService;
    private browser: BrowserService;
    private dataService: DataService;
    private onServiceErrorHandler: ErrorHandlerService;
    private userSettings: UserSettingsService;
    private unitService: UnitService;
    private tracking: TrackingService;

    private removeModelWatch: Function;
    private modelWatch: IModelWatch;

    private _model: { [property: number]: Object };

    constructor(projectDeps: IProjectDeps, project?: IProjectConstructor) {
        super();

        this.localization = projectDeps.localization;
        this.$http = projectDeps.$http;
        this.logger = projectDeps.logger;
        this.changes = projectDeps.changesService;
        this.document = projectDeps.document;
        this.$q = projectDeps.$q;
        this.$rootScope = projectDeps.$rootScope;
        this.guid = projectDeps.guid;
        this.promise = projectDeps.promise;
        this.dateTime = projectDeps.dateTime;
        this.onServiceErrorHandler = projectDeps.onServiceErrorHandler;
        this.modal = projectDeps.modal;
        this.browser = projectDeps.browser;
        this.userSettings = projectDeps.userSettings;
        this.dataService = projectDeps.dataService;
        this.tracking = projectDeps.tracking;
        this.unitService = projectDeps.unitService;

        this.updateDebounce = debounce((...args: any[]) => { this.$rootScope.$apply(() => { this.updateDebounceInternal.apply(this, args); }); }, updateDebounce);
        this.updateDefer = this.$q.defer<Project>();

        if (project != null) {
            this.id = project.id;
            this.name = project.name;
            this.designs = project.designs;
            this.directChildDesigns = project.directChildDesigns;
            this.boms = project.boms;
            this.subProjects = project.subProjects;
            this.parentId = project.parentId;
            this.projectType = project.projectType;
            this.createDate = project.createDate;
            this.changeDate = project.changeDate;
            this.owner = project.owner;
            this.isCompanyProject = project.isCompanyProject;
            this.users = [];
            this.readOnly = project.readonly;
        }

        this.designs = this.designs || {};
        this.directChildDesigns = this.directChildDesigns || {};
        this.boms = this.boms || [];
        this.subProjects = this.subProjects || {};
        this.parentId = this.parentId || null;
        this.projectType = this.projectType || ProjectType.common;
        this.modelChanges = new TrackChanges({
            collapse: true,
            ignoreUndefined: true,
            shallowChanges: true,
            changesService: this.changes,
            loggerService: this.logger
        });

        this.model = this.model != null ? this.model : {};

        this.uniqueId = this.guid.new();
        this.scopeProjectId = `project_${this.uniqueId.replace(/-/g, '_')}`;
        this.$rootScope[this.scopeProjectId] = this;

        if (this.removeModelWatch != null) {
            this.removeModelWatch();

            this.removeModelWatch = null;
            this.modelWatch = null;
        }

        this.removeModelWatch = this.$rootScope.$watch<Object>(`${this.scopeProjectId}.model`, (model, oldModel) => {
            if (model === oldModel) {
                return;
            }

            // call BL and update
            this.updatePurchaserProperties();

            if (Project.updateQueue[this.id] == undefined) {
                Project.updateQueue[this.id] = this.updateDefer;
            }
        }, true);

        this.modelWatch = this.$rootScope['$$watchers'][0];

        const userSettings = {
            LanguageLCID: this.userSettings.settings.user.general.languageId.value,
            RegionId: this.userSettings.settings.user.general.regionId.value,
            UnitLength: this.userSettings.settings.user.units.lengthId.value,
            UnitVolume: this.userSettings.settings.user.units.volumeId.value,
            UnitArea: this.userSettings.settings.user.units.areaId.value,
            SelectAll: false,
            SelectOne: null as string
        };

        // Init empty object
        this.purchaserDataEntity = ({
            BomDetails: [],
            Boms: [],
            Designs: [],
            Options: userSettings
        } as PurchaserDataEntity);

        if (Project.updateQueue == null || Project.updateResults == null) {
            Project.updateQueue = {};
            Project.updateResults = {};
        }
    }

    private static updateProjectsFromResponseData(project: Project, data: PropertyUpdateResultEntity) {
        Project.updateResults[project.id] = data;
        if (Object.keys(Project.updateQueue).length <= Object.keys(Project.updateResults).length) {
            Object.values(Project.updateQueue).forEach(d => d.promise.then(p => {
                // add missing designs
                p.purchaserDataEntity.Designs.forEach(d => {
                    if (!Project.updateResults[p.id].PurchaserData.Designs.some(de => de.DesignId == d.DesignId && de.DesignConnectionId == d.DesignConnectionId)) {
                        Project.updateResults[p.id].PurchaserData.Designs.push(d);
                    }
                });

                // set data
                p.updatePurchaserData(Project.updateResults[p.id]);

                p.trigger(ProjectEvent.stateChanged, p, Project.updateResults[p.id]);

                delete Project.updateQueue[p.id];
                delete Project.updateResults[p.id];
            }));
        }
    }
    public get model() {
        return this._model;
    }
    public set model(model) {
        this._model = model;
        this.modelChanges.set(this.model);
    }

    public loadUsers(): ng.IPromise<any> {
        return this.document.getUsersOnProjectById(this.id).then((response) => {
            this.users = response;
        });
    }

    public loadPurchaserDataEntityForProject(selectAll: boolean, loadFromStart?: boolean, stateId?: string, autoSelectDesignId?: string) {
        let localPurchaserDataEntity = this.purchaserDataEntity;

        if (loadFromStart) {
            this.fullyLoaded = false;
            // user settings
            const userSettings = {
                LanguageLCID: this.userSettings.settings.user.general.languageId.value,
                RegionId: this.userSettings.settings.user.general.regionId.value,
                UnitLength: this.userSettings.settings.user.units.lengthId.value,
                UnitVolume: this.userSettings.settings.user.units.volumeId.value,
                UnitArea: this.userSettings.settings.user.units.areaId.value,
                SelectAll: selectAll,
                SelectOne: autoSelectDesignId
            };
            // reset all data in purchaser data entity
            localPurchaserDataEntity = {
                Designs: [],
                Boms: [],
                BomDetails: [],
                WorkingCondition: null,
                ProjectDesignVersion: null,
                IsTemplate: null,
                ProjectName: null,
                DateCreated: null,
                DateAccessed: null,
                DateModified: null,
                Options: userSettings
            };
        }

        const designXmls: DesignXMLEntity[] = [];
        const defer = this.$q.defer<DesignEntity[]>();

        let designs: IDesignListItem[] = Object.keys(this.designs).map(key => this.designs[key]);
        for (const key in this.subProjects) {
            designs = [...designs, ...Object.keys(this.subProjects[key].designs).map(k => this.subProjects[key].designs[k])];
        }

        // unique by id
        designs = uniqBy(designs, design => design.id);

        // remove already loaded
        if (!loadFromStart) {
            designs = designs.filter(design => !localPurchaserDataEntity.Designs.some(designEntity => designEntity.DesignId == design.id));
        }

        // sort by create date
        designs = sortBy(designs, design => design.id == autoSelectDesignId ?
            Number.MAX_VALUE :
            design.createDate instanceof Date ? design.createDate.getTime() : design.createDate as any as number
        );

        // take first Project.loadSize designs
        designs = designs.slice(Math.max(0, designs.length - Project.loadSize));

        this.loading = true;

        let projectDesigns: ng.IPromise<IDocumentServiceResponse[]> = null;

        // get design files
        if (designs.length > 0) {
            projectDesigns = this.document.openDesigns(designs);
        }

        this.$q.resolve(projectDesigns)
            .then<IConvertAndCheckDesignResponse>((designs) => {
                const items: IConvertAndCheckDesignRequestItemExtended[] = [];

                if (designs != null) {
                    for (const design of designs) {
                        items.push({
                            RawProjectDesign: JSON.stringify(design.filecontent),
                            DesignId: design.documentId,
                            StorageFormat: ProjectDesingFileStorageFormat.JSON,
                            DesignName: design.documentName
                        } as IConvertAndCheckDesignRequestItemExtended);
                    }
                }

                return this.convertAndCheckDesign(items);
            })
            .then((response) => {
                for (const responseItem of response.ConvertAndCheckDesignResponseItems) {
                    designXmls.push({
                        DesignId: responseItem.DesignId,
                        DesignXml: JSON.stringify(responseItem.ProjectDesign),
                        ValidationStatus: responseItem.ValidationStatus,
                        DesignName: responseItem.DesignName
                    } as DesignXMLEntity);
                }

                let projectWithSubIds = this.subProjects != null ? Object.keys(this.subProjects) : [];
                projectWithSubIds = projectWithSubIds.concat(this.id);

                const url = `${environment.purchaserApplicationWebServiceUrl}CreatePurchaserProjectData`;

                const params = {
                    DesignXmls: designXmls,
                    ProjectId: this.id,
                    ProjectWithSubIds: projectWithSubIds,
                    Entity: localPurchaserDataEntity
                } as CreatePurchaserProjectDataRequest;

                // load purchaser data
                this.$http.post<PropertyUpdateResultEntity>(url, params).
                    then((updateResult) => {
                        const loadedDesigns = !loadFromStart
                            ? updateResult.data.PurchaserData.Designs.filter(newDesign => !localPurchaserDataEntity.Designs.some(oldDesign => oldDesign.DesignId == newDesign.DesignId))
                            : updateResult.data.PurchaserData.Designs;

                        this.updatePurchaserData(updateResult.data, designs);

                        // trigger update event
                        this.trigger(ProjectEvent.stateChanged, this, updateResult.data, stateId);

                        defer.resolve(loadedDesigns);
                    })
                    .catch((response) => {
                        this.logger.logServiceError(response, 'PurchaserApplication', 'CreatePurchaserProjectData');

                        defer.reject();
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            })
            .catch(() => {
                this.purchaserDataEntity.Designs = [];
                const data = {
                    PurchaserData: this.purchaserDataEntity,
                    Properties: {}
                } as PropertyUpdateResultEntity;

                this.updatePurchaserData(data, null);
                this.trigger(ProjectEvent.stateChanged, this, data, null);

                this.loading = false;
                defer.reject();
            });

        return defer.promise;
    }

    public importDesignToPurchaserDataEntity(designId: string, designName: string, designXml: any, validationStatus: number) {
        this.loading = true;
        const url = `${environment.purchaserApplicationWebServiceUrl}CreatePurchaserProjectDataFromFile`;
        const params = {
            entity: this.purchaserDataEntity,
            designXml: {
                DesignId: designId,
                DesignXml: JSON.stringify(designXml),
                ValidationStatus: validationStatus,
                DesignName: designName
            } as DesignXMLEntity
        };

        this.$http.post<PropertyUpdateResultEntity>(url, params).
            then((updateResult) => {
                this.updatePurchaserData(updateResult.data);

                // trigger update event
                this.trigger(ProjectEvent.stateChanged, this, updateResult.data);
            })
            .catch((response) => {
                this.logger.logServiceError(response, 'PurchaserApplication', 'CreatePurchaserProjectDataFromFile');
            })
            .finally(() => {
                this.loading = false;
            });
    }

    public importNewAnchorToPurchaserDataEntity(manualDesign: ManualDesignEntity) {
        this.loading = true;
        manualDesign.CreatedDate = new Date(Date.now());
        const url = `${environment.purchaserApplicationWebServiceUrl}CreatePurchaserProjectDataFromManual`;
        const params = {
            entity: this.purchaserDataEntity,
            manualDesignEntity: manualDesign
        };
        const config: IRequestConfig = { ignoreErrors: true };

        return this.$http.post<PropertyUpdateResultEntity>(url, params, config).
            then((updateResult) => {
                this.tracking.trackOnCustomConnectionCreated();

                this.updatePurchaserData(updateResult.data);

                this.document.loadNumberOfDesignsPerProject();

                // trigger update event
                this.trigger(ProjectEvent.stateChanged, this, updateResult.data);
            })
            .catch((response) => {
                this.logger.logServiceError(response, 'PurchaserApplication', 'CreatePurchaserProjectDataFromManual');
                if (response.status == 404) {
                    this.onServiceErrorHandler.showProjectArchivedModal();
                }
            })
            .finally(() => {
                this.loading = false;
            });
    }

    /**
     * Change the model without triggering changes.
     * @param fn The function that changes the model.
     */
    public changeModel(fn: (model: { [property: number]: Object }) => void) {
        const oldModel = cloneDeep(this.model);
        let changes: { [property: string]: Change };

        try {
            fn(this.model);
        }
        finally {
            changes = this.changes.getShallowChanges(oldModel, this.model);

            // set watch values
            if (this.modelWatch != null) {
                for (const propertyId in changes) {
                    this.modelWatch.last[propertyId] = cloneDeep(changes[propertyId].newValue);
                }
            }

            // set track changes values
            if (Object.keys(changes).length > 0) {
                for (const propertyId in changes) {
                    this.modelChanges.setOriginalProperty(propertyId, cloneDeep(changes[propertyId].newValue));
                }
            }
        }

        return changes;
    }

    public convertAndCheckDesign(items: IConvertAndCheckDesignRequestItemExtended[]): ng.IPromise<IConvertAndCheckDesignResponse> {
        if (items.length === 0) { // optimization: if there are no items don't call engineer at all
            return this.$q.when({
                ConvertAndCheckDesignResponseItems: [],
                ElapsedMilliseconds: 0
            } as IConvertAndCheckDesignResponse);
        }

        const url = `${environment.baseplateCalculationWebServiceUrl}ConvertAndCheckDesign`;
        const params = {
            ConvertAndCheckDesignRequestItems: items,
            Language: this.localization.selectedLanguage,
            NumberDecimalSeparator: this.localization.numberFormat.NumberDecimalSeparator,
            NumberThousandsSeparator: this.localization.numberFormat.NumberGroupSeparator
        } as ConvertAndCheckDesignRequest;

        // check design status
        return this.$http.post<IConvertAndCheckDesignResponse>(url, params)
            .then((response) => {
                this.tracking.trackDesignVerificationTime(response.data.ElapsedMilliseconds);

                // Reset design name because it is lost on ConvertAndCheckDesign
                response.data.ConvertAndCheckDesignResponseItems.map(responseItem => {
                    responseItem.DesignName = items.find(i => i.DesignId == responseItem.DesignId)?.DesignName;
                });

                return response.data;
            });
    }

    public updatePurchaserProperties() {
        this.loading = true;
        this.updateId = this.guid.new();

        if (updateDebounce == 0) {
            this.updateDebounceInternal(this.updateId);
        }
        else {
            this.updateDebounce(this.updateId);
        }

        return !this.loading ? null : this.updateDefer.promise;
    }

    private updatePurchaserData(data: PropertyUpdateResultEntity, requestedDesigns?: IBaseDesign[]) {
        this.purchaserDataEntity = data.PurchaserData;
        this.updateFromProperties(data.Properties as any);

        // remove designs that we requested but we didn't get them back
        if (requestedDesigns != null) {
            const missingDesignIds = requestedDesigns.filter(requestedDesign => !this.purchaserDataEntity.Designs.some(design => design.DesignId == requestedDesign.id)).map(design => design.id);

            for (const key in this.designs) {
                if (missingDesignIds.includes(this.designs[key].id)) {
                    delete this.designs[key];
                }
            }

            for (const pkey in this.subProjects || {}) {
                for (const dkey in this.subProjects[pkey].designs || {}) {
                    if (missingDesignIds.includes(this.subProjects[pkey].designs[dkey].id)) {
                        delete this.subProjects[pkey].designs[dkey];
                    }
                }
            }
        }

        // is fully loaded
        let designs: IDesignListItem[] = Object.keys(this.designs).map(key => this.designs[key]);
        for (const key in this.subProjects) {
            designs = [...designs, ...Object.keys(this.subProjects[key].designs).map(k => this.subProjects[key].designs[k])];
        }

        const notLoadedDesigns = designs.filter(design => !this.purchaserDataEntity.Designs.some(designEntity => designEntity.DesignId == design.id));

        this.fullyLoaded = notLoadedDesigns.length == 0;
    }

    private updateFromProperties(properties: UIProperties) {
        const modelChanges = this.updateModel(properties);

        // join changes
        const changes: { [propertyId: string]: { modelChange: Change, isHidden: boolean, isDisabled: boolean, allowedValues: number[] } } = {};

        // model changes
        for (const modelChangeKey in modelChanges) {
            const propertyId = parseInt(modelChangeKey, 10);
            const modelChange = modelChanges[modelChangeKey];

            if (changes[propertyId] == null) {
                changes[propertyId] = { modelChange: null, isHidden: null, isDisabled: null, allowedValues: null };
            }

            changes[propertyId].modelChange = modelChange;
        }
    }

    private updateModel(properties: UIProperties) {
        return this.changeModel((model) => {
            for (const name in properties) {
                const propertyConfig: UIPropertyConfig = (properties as any)[name];

                // don't update the model if there's already a change pending
                if (propertyConfig != null && !this.modelChanges.changes.some((change) => change.name == propertyConfig.Property.toString())) {
                    model[propertyConfig.Property] = propertyConfig.Value;
                }
            }
        });
    }

    private clearModelWatch() {
        if (this.modelWatch != null) {
            for (const propertyId in this.model) {
                this.modelWatch.last[propertyId] = this.model[propertyId];
            }
        }
    }

    private cancelHttpUpdate() {
        if (this.updateHttpCancel != null) {
            this.updateHttpCancel.resolve();
            this.updateHttpCancel = null;

            // get the canceled changes back
            if (this.lastModelChanges != null) {
                this.modelChanges.changes = this.lastModelChanges.concat(this.modelChanges.changes);
                this.lastModelChanges = null;

                this.modelChanges.observe();
            }

            // print
            this.logger.log('Calculate canceled');
        }
    }

    private updateDebounceInternal(updateId: string) {
        this.modelChanges.observe();
        this.cancelHttpUpdate();

        if (this.modelChanges.changes != null && this.modelChanges.changes.length > 0) {
            this.updateHttpCancel = this.$q.defer<void>();

            const url = `${environment.purchaserApplicationWebServiceUrl}UpdateProperties`;
            const params = {
                data: this.purchaserDataEntity,
                properties: this.modelChanges.changes.map((change) => {
                    return {
                        Property: parseInt(change.name, 10),
                        ValueJson: JSON.stringify(change.newValue)
                    } as UIPropertyValue;
                })
            };

            this.$http.post<PropertyUpdateResultEntity>(url, params, { timeout: this.updateHttpCancel.promise })
                .then((response) => {
                    // trigger update event
                    Project.updateProjectsFromResponseData(this, response.data);

                    // resolve update promise
                    if (this.updateId == updateId) {
                        this.updateDefer.resolve(this);
                        this.updateDefer = this.$q.defer<Project>();
                    }

                    return response;
                })
                .catch((response: ng.IHttpResponse<Object>) => {
                    delete Project.updateQueue[this.id];

                    // if request was canceled don't reject the promise since a new request was started
                    if (!this.promise.isResponseTimeoutResolved(response)) {
                        this.logger.logServiceError(response, 'PurchaserApplication', 'UpdateProperties');

                        // load last valid state if we are not changing the page
                        if (response.status != 401) {

                        }

                        // reject update promise
                        if (this.updateId == updateId) {
                            this.updateDefer.reject(response);
                            this.updateDefer = this.$q.defer<Project>();
                        }
                    }

                    return response;
                })
                .then((response) => {
                    // remove loading flag if request is not canceled
                    if (!this.promise.isResponseTimeoutResolved(response)) {
                        this.updateHttpCancel = null;
                        this.lastModelChanges = null;
                    }
                })
                .finally(() => {
                    if (this.updateId == updateId) {
                        this.loading = false;
                    }
                });

            // print changes
            this.logger.logGroup(new LogMessage({
                message: 'Update'
            }), this.modelChanges.changes.map((change) => {
                const metaData = PropertyMetaData.getById(change.name as any);

                return new LogMessage({
                    message: (metaData != null ? metaData.name : change.name) + ': %o => %o',
                    args: [this.trim(change.oldValue), this.trim(change.newValue)]
                });
            }));

            // clear model changes
            this.lastModelChanges = this.modelChanges.changes.slice();  // we might need them if we cancel the request
            this.modelChanges.clear();
        }
    }

    private trim(value: any, length: number = 100) {
        if (value != null && typeof value == 'string') {
            const stringValue = (value as string);

            return stringValue.length > length ? stringValue.substring(0, length) + ' ...' : stringValue;
        }

        return value;
    }

    private onConfirmOrderUrlInformation(orderUrl: string) {
        this.modal.confirmChange.close();
        window.open(orderUrl, '_blank');
    }

    private onCancelOrderUrlInformation() {
        this.modal.confirmChange.close();
    }
}
